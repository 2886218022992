import React, { useState, useEffect, useContext } from "react";
import { auth, db } from "../../config/firebase";
import { collection, getDocs, addDoc, serverTimestamp, deleteDoc, doc, query, orderBy } from "firebase/firestore";
import { DashboardContext } from "./Dashboard";
import { Loading } from "../../components/Loading";


function useDashboardContext() {
  let context = useContext(DashboardContext);
  // If context is undefined, we know we used RadioGroupItem
  // outside of our provider so we can throw a more helpful
  // error!
  if (context === undefined) {
    throw Error(
      "DashboardContext must be used inside of Dashboard component, " +
        "otherwise it will not function correctly."
    );
  }

  // Because of TypeScript's type narrowing, if we make it past
  // the error the compiler knows that context is always defined
  // at this point, so we don't need to do any conditional
  // checking on its values when we use this hook!
  return context;
}


type Entry = {
  id: string;
  dateCreated: Date;
  name: string,
  phone: string,
  notes: string,
  assignedToResource: string;
  assignedToService: string;
  status: string;
  dateRemoved: Date;
};

export const Recent = () => {
  const { business } = useDashboardContext();
  const [entries, setEntries] = useState<Entry[]>([]);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().substring(0, 10));
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchEntries = async () => {
      const pagerAuditRef = collection(db, "business", business.id, "pagerAudit", selectedDate, "entries");
      const qry = query(pagerAuditRef, orderBy("dateRemoved", "desc"));
      setLoading(true);
      const snapshot = await getDocs(qry);
      setLoading(false);
      const entries = snapshot.docs.map((doc: any) => ({ id: doc.id, ...doc.data(), dateCreated: new Date(doc.data().dateCreated.toDate()), dateRemoved: new Date(doc.data().dateRemoved.toDate()) }));
      setEntries(entries);
    };
    fetchEntries();
  }, [business.id, selectedDate]);

  const handleDateChange = (event: any) => {
    setSelectedDate(event.target.value);
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Recent Pagers</h2>
      <div className="flex items-center mb-4">
        <label htmlFor="date" className="mr-2 font-semibold">
          Date:
        </label>
        <input type="date" id="date" value={selectedDate} onChange={handleDateChange} className="border border-gray-300 rounded p-1" />
      </div>
      {loading? <Loading /> : 
      <>
        {entries.length > 0 ? (
          <ul className="space-y-4">
            {entries.map((entry) => (
              <li key={entry.id} className="border border-gray-300 p-2 rounded">
                <div className="font-semibold">Name: {entry.name}</div>
                <div>Resource: {entry.assignedToResource}</div>
                <div>Service: {entry.assignedToService}</div>
                <div>Served at: {entry.dateRemoved.toLocaleString()}</div>
              </li>
            ))}
          </ul>
        ) : (
          <div className="text-center">No served pagers found for the selected date.</div>
        )}
      </>
      }
    </div>
  );
};