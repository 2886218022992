const OperatingHoursForm = (props:any) => {
  const {handleLastStep, hours, setHours} = props;
  const days = [
    { label: "Monday", value: "Monday" },
    { label: "Tuesday", value: "Tuesday" },
    { label: "Wednesday", value: "Wednesday" },
    { label: "Thursday", value: "Thursday" },
    { label: "Friday", value: "Friday" },
    { label: "Saturday", value: "Saturday" },
    { label: "Sunday", value: "Sunday" },
  ];

  const handleOpenChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setHours({ ...hours, open: value });
  };

  const handleCloseChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setHours({ ...hours, close: value });
  };

  const handleDaysChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (e.target.checked) {
      setHours({ ...hours, days: [...hours.days, value] });
    } else {
      setHours({ ...hours, days: hours.days.filter((day : string) => day !== value) });
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.currentTarget.disabled = true;
    handleLastStep();
  };

  return (
    <form className="grid grid-cols-1 gap-4 p-5 m-auto max-w-lg md:grid-cols-2 lg:max-w-4xl" onSubmit={handleSubmit}>
      <div className="relative col-span-1">
        <label className="block text-lg font-medium mb-2" htmlFor="open">
          Open Time
        </label>
        <input
          type="time"
          name="open"
          id="open"
          value={hours? hours.open: ''}
          className="bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 rounded-md py-2 px-4 block w-full leading-5 text-gray-700 transition duration-150 ease-in-out"
          onChange={handleOpenChange}
        />
      </div>
      <div className="relative col-span-1">
        <label className="block text-lg font-medium mb-2" htmlFor="close">
          Close Time
        </label>
        <input
          type="time"
          name="close"
          id="close"
          value={hours? hours.close: ''}
          className="bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 rounded-md py-2 px-4 block w-full leading-5 text-gray-700 transition duration-150 ease-in-out"
          onChange={handleCloseChange}
        />
      </div>
      <div className="flex flex-wrap -mx-3 mb-6 col-span-2">
        <div className="px-3 mb-6">
          <label className="block tracking-wide text-gray-700 text-lg font-medium mb-2">
            Days
          </label>
          <div className="grid grid-cols-2 grid-rows-2 gap-4">
            {days.map((day) => (
              <label className="inline-flex items-center" key={day.value}>
                <input
                  type="checkbox"
                  name="days"
                  value={day.value}
                  checked={hours? hours.days.includes(day.value): false}
                  onChange={handleDaysChange}
                />
                <span className="ml-2 text-gray-600">{day.label}</span>
              </label>
            ))}
          </div>
        </div>
      </div>
      <div className="text-center mt-6 mb-6 col-span-2">
        <button
          className="bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded w-full uppercase"
          type="submit"
          onClick={handleClick}
        >
          Save
        </button>
      </div>
    </form>
  );
};

export default OperatingHoursForm;
