import { loadStripe, Stripe } from '@stripe/stripe-js';

let stripePromise: Promise<Stripe | null>;
export const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe("pk_live_51MLKmJL1xbGvmXauXt8TffXYxhc5jhjjZtVzrNXLOOiNl5iQqDLwIR8oDsxF9O68tPJfSW7EMcfp8bo4as4EOLPS00YcwXdVL3");
  }
  return stripePromise;
};

export const PUBLIC_STRIPE_PRICE_ID = "price_1MsHXOL1xbGvmXauVl9ELsTr";