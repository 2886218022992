import React, { useState, useEffect, useContext } from "react";
import { auth, db } from "../../config/firebase";
import { collection, getDocs, addDoc, serverTimestamp, deleteDoc, doc, query, orderBy, where } from "firebase/firestore";
import { DashboardContext } from "./Dashboard";
import { Loading } from "../../components/Loading";
import { Subscribe } from "../pricing/Subscribe";

function useDashboardContext() {
  let context = useContext(DashboardContext);
  // If context is undefined, we know we used RadioGroupItem
  // outside of our provider so we can throw a more helpful
  // error!
  if (context === undefined) {
    throw Error(
      "DashboardContext must be used inside of Dashboard component, " +
        "otherwise it will not function correctly."
    );
  }

  // Because of TypeScript's type narrowing, if we make it past
  // the error the compiler knows that context is always defined
  // at this point, so we don't need to do any conditional
  // checking on its values when we use this hook!
  return context;
}

type Plan = 'free' | 'paid';

interface Sub {
  plan: Plan;
  active: boolean;
  subscribedDate: Date;
}

export const Subscription: React.FC = () => {

  const { business, user } = useDashboardContext();

  const [subscription, setSubscription] = useState<Sub | null>(null);

  useEffect(() => {
    const getSubscription = async () => {
      const subscriptionRef = collection(db, "business", business.id, "subscription");
      const qry = query(subscriptionRef, where("plan", "==", "paid"));
      const snapshot = await getDocs(qry);
      if (snapshot.size > 0) {
        const subscriptionData = {
          ...snapshot.docs[0].data(),
          subscribedDate: snapshot.docs[0].data().subscribedDate.toDate()
        } as Sub;
        setSubscription(subscriptionData);
      } else {
        setSubscription({plan:'free', active:true, subscribedDate: new Date(business.createdAt.toDate())});
      }
    };
    if(business.id){
      getSubscription();
    }
  }, [business]);

  const handleUnsubscribe = async () => {
    if (subscription?.plan === 'paid') {

    }
  };

  return (
    <div className="p-4 border rounded-lg mt-10">
      <h2 className="text-xl font-semibold mb-2">Subscription Details</h2>
      {subscription ? (
        <>
          <p className="mb-2">
            Plan: <span className="font-semibold first-letter:uppercase">{subscription.plan}</span>
          </p>
          <p className="mb-2">
            Status:{' '}
            <span className={`font-semibold ${subscription.active ? 'text-green-500' : 'text-red-500'}`}>
              {subscription.active ? 'Active' : 'Inactive'}
            </span>
          </p>
          <p className="mb-2">
            Subscribed Date:{' '}
            <span className="font-semibold">
              {subscription.subscribedDate.toDateString()}
            </span>
          </p>
          <p className="mb-2">
            Email:{' '}
            <span className="font-semibold">
              {user.email}
            </span>
          </p>
          {subscription.plan === 'paid' && (
            <a href="https://billing.stripe.com/p/login/test_cN28wHboi474cEg3cc">
              <button
                className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600"
              >
                Manage Subscription
              </button>
            </a>
          )}
          {subscription.plan === 'free' && (
            <Subscribe user={user} />
          )}
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};