import {getStripe, PUBLIC_STRIPE_PRICE_ID} from "../../../config/stripe";

export const Subscribe = (props: any) => {
  const {user} = props;
  async function handleCheckout() {
    const stripe = await getStripe();
    if(stripe){
      const { error } = await stripe.redirectToCheckout({
        lineItems: [
          {
            price: PUBLIC_STRIPE_PRICE_ID,
            quantity: 1,
          },
        ],
        mode: 'subscription',
        successUrl: `${window.location.origin}/dashboard`,
        cancelUrl: `${window.location.origin}/dashboard`,
        customerEmail: `${user.email}`,
      });
      console.warn(error.message);
    }
  }
  return <button className="m-auto font-semibold text-white bg-gray-500  hover:bg-gray-600 py-2 px-4 rounded-lg" onClick={handleCheckout}>Subscribe</button>
}