import React, { useState, useEffect } from 'react';
import { ShareModal } from './shareModal';

export const PagerEntry = (props: any) => {
  const {pager, sharePager, removePager, markServed, sendAlert} = props

  const [waitTime, setWaitTime] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentTime = new Date().getTime();
      setWaitTime(Math.round((currentTime - pager.dateCreated) / 60000));
    }, 1000);
    return () => clearInterval(intervalId);
  }, [pager.dateCreated]);

  const days = Math.floor(waitTime / 1440);
  const hours = Math.floor((waitTime - days * 1440) / 60);
  const minutes = waitTime % 60;

  return (
    <li key={pager.id} className="border border-gray-300 p-2 rounded">
      <div className="font-semibold">Name: {pager.name}</div>
      <div>Wait Time: {days > 0 ? `${days}d ` : ''}{hours > 0 ? `${hours}h ` : ''}{minutes}m</div>
      <div>Assigned To Resource: {pager.assignedToResource}</div>
      <div>Assigned To Service: {pager.assignedToService}</div>
      <div>Notes: {pager.notes}</div>
      <div>
        <ShareModal pager={pager} />
        <button className="bg-blue-500 text-white p-2 rounded mr-2" onClick={() => markServed(pager)}>Serve</button>
        <button className="bg-yellow-500 text-white p-2 rounded mr-2" onClick={() => sendAlert(pager)}>Alert</button>
        <button className="bg-red-500 text-white p-2 rounded mr-2" onClick={() => removePager(pager)}>Remove</button>
      </div>
    </li>
  )
}