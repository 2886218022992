import { useState } from "react";
import Modal from "react-modal";
import email from "../../assets/email.png";
import sms from "../../assets/sms.png";
import whatsapp from "../../assets/whatsapp.png";
const QRious = require('qrious');

interface Pager {
  id: string;
  dateCreated: Date;
  name: string,
  phone: string,
  notes: string,
  assignedToResource: string;
  assignedToService: string;
  status: string;
  url: string;
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
    padding: '30px',
    maxWidth: '400px',
    width: '100%',
    maxHeight: '80vh',
    overflow: 'auto',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
  },
};



export const ShareModal = ({ pager }: { pager: Pager }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const qr = new QRious({
    value: pager.url,
    size: 200,
  });

  return (
    <>
      <button className="bg-green-500 text-white p-2 rounded mr-2" onClick={openModal}>Share</button>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Share Pager Modal"
        appElement={document.getElementById('root') || undefined}
      >
        <div className="text-xl font-bold mb-4">Share Buzzer</div>
        <div className="flex flex-col items-center mb-4">
          <img src={qr.toDataURL()} alt="Pager QR code" className="mb-2" />
          {/* <p className="text-sm text-gray-600 mb-2">{pager.url}</p> */}
        </div>
        <div className="flex justify-between space-x-6">
          <a
            href={`mailto:?subject=Check out the status of your Buzzerr at!&body=${encodeURIComponent(
              pager.url
            )}`}
            className="text-indigo-600 hover:text-indigo-800 text-center"
          >
            <img src={email} alt="email" width="52" height="36" className="mx-auto"/>
            <div>Email</div>
          </a>
          <a
              href={`sms:${pager.phone}?&body=${encodeURIComponent(pager.url)}`}
              className="text-indigo-600 hover:text-indigo-800 text-center"
            >
              <img src={sms} alt="sms" width="52" height="36" className="mx-auto"/>
              <div>SMS Message</div>
            </a>

            <a
                href={`https://wa.me/${pager.phone}?text=${encodeURIComponent(pager.url)}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-indigo-600 hover:text-indigo-800 text-center"
              >
                <img src={whatsapp} alt="whatsapp" width="52" height="36"className="mx-auto"/>
                <div>Whatsapp</div>
              </a>
            </div>
        </Modal>
    </>
  );
};
