import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/pager.png";
import { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../config/firebase";
import { signOut } from "firebase/auth";

export const Navbar = () => {
  const navigate = useNavigate();
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [user] = useAuthState(auth);
  const logout = async () => {
    await signOut(auth);
    navigate("/");
  }
  return (
    <div className="flex mb-16 rounded-lg">
      <div className="flex-1 px-2 mx-2">
        <a className="flex items-center" href="/">
          <img src={logo} alt="buzzerr.app" width="75" height="75" />
          <h2 className="ml-5 text-2xl font-bold lg:text-4xl">Buzzerr</h2>
        </a>
      </div>
      <div className="hidden flex-none px-2 mx-2 lg:flex">
        <div className="flex items-stretch">
          <Link className="my-auto mr-7 bg-transparent font-semibold hover:bg-gray-300 py-2 px-4 rounded-lg" to="/pricing">Pricing</Link>
          <Link className="my-auto mr-7 bg-transparent font-semibold hover:bg-gray-300 py-2 px-4 rounded-lg" to="/contact">Contact</Link>
          {
            !user && (
              <Link className="my-auto mr-7 bg-transparent font-semibold hover:bg-gray-300 py-2 px-4 rounded-lg" to="/login">Sign In</Link>
            )
          }
          {
            user && (
              <>
                <Link className="my-auto mr-7 bg-transparent font-semibold hover:bg-gray-300 py-2 px-4 rounded-lg" to="/dashboard">Dashboard</Link>
                <button className="my-auto mr-7 bg-transparent font-semibold hover:bg-gray-300 py-2 px-4 rounded-lg inline-flex items-center" onClick={logout}><svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="mr-2" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>Log Out</button>
              </>
            )
          }
        </div>
      </div>
      <div className="flex flex-col">
        <div className="ml-3 my-auto relative lg:hidden z-50">
          <div>
            <button className="text-2xl bg-transparent font-bold hover:bg-gray-300 py-2 px-4 rounded-lg" onClick={() => setIsNavOpen((prev) => !prev)}>
              <span className="sr-only">Open user menu</span>
              <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
            </button>
          </div>
        </div>
      </div>
      <section className="MOBILE-MENU flex lg:hidden">
          <div className={isNavOpen ? "showMenuNav" : "hideMenuNav"}>
            <ul className="flex flex-col items-center justify-between min-h-[250px]">
              <Link className="my-auto mr-7 bg-transparent font-semibold hover:bg-gray-300 py-2 px-4 rounded-lg" to="/pricing" onClick={() => setIsNavOpen((prev) => !prev)}>Pricing</Link>
              <Link className="my-auto mr-7 bg-transparent font-semibold hover:bg-gray-300 py-2 px-4 rounded-lg" to="/contact" onClick={() => setIsNavOpen((prev) => !prev)}>Contact</Link>

              {
                !user && (
                  <Link className="my-auto mr-7 bg-transparent font-semibold hover:bg-gray-300 py-2 px-4 rounded-lg" to="/login" onClick={() => setIsNavOpen((prev) => !prev)}>Sign In</Link>
                )
              }
              {
                user && (
                  <>
                    <Link className="my-auto mr-7 bg-transparent font-semibold hover:bg-gray-300 py-2 px-4 rounded-lg" to="/dashboard" onClick={() => setIsNavOpen((prev) => !prev)}>Dashboard</Link>
                    <button className="my-auto mr-7 bg-transparent font-semibold hover:bg-gray-300 py-2 px-4 rounded-lg" onClick={() => {setIsNavOpen((prev) => !prev); logout();}}>
                    <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="mr-2" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>Log Out</button>
                  </>
                )
              }
            </ul>
          </div>
        </section>
        <style>{`
          .hideMenuNav {
            display: none;
          }
          .showMenuNav {
            display: block;
            position: absolute;
            width: 100%;
            height: 100vh;
            top: 0;
            left: 0;
            background: white;
            z-index: 10;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
          }
        `}</style>
    </div>

  )
}