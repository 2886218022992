import React, { useState } from 'react';
import { countryCodes } from './countryCodes';


export const PhoneInput = (props:any) => {

  const {onPhoneChange, defaultCountry} = props;
  const defaultSelectedCountry = countryCodes.find(({ code }) => code === defaultCountry)!;

  const [selectedCountry, setSelectedCountry] = useState(defaultSelectedCountry);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [dialCode, setDialCode] = useState(defaultSelectedCountry.dial_code);

  return (
    <div className="flex">
      <select
        className="form-select w-20"
        value={selectedCountry.code}
        onChange={(e) => {
          const selectedCode = e.target.value;
          const country = countryCodes.find(({ code }) => code === selectedCode)!;
          setSelectedCountry(country);
          setDialCode(country.dial_code);
          onPhoneChange(country.dial_code + phoneNumber);
        }}
      >
        {countryCodes.map(({ name, dial_code, flag, code }) => (
          <option key={name} value={code}>
            {flag} {dial_code}
          </option>
        ))}
      </select>
      <input
        className="form-input w-64 ml-2 appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
        type="tel"
        placeholder="Enter phone number"
        value={phoneNumber}
        onChange={(e) => {
          setPhoneNumber(e.target.value);
          onPhoneChange(dialCode + e.target.value);
        }}
      />
    </div>
  );
};
