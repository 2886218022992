import React, { useState, useEffect } from 'react';

function TermsOfService() {
  const [terms, setTerms] = useState('');

  useEffect(() => {
    fetch('/terms-of-service.html')
      .then(response => response.text())
      .then(data => setTerms(data));
  }, []);

  return (
    <div dangerouslySetInnerHTML={{ __html: terms }} />
  );
}

export default TermsOfService;