import React, { useState, useContext } from 'react';
import businessimg from "../../assets/business.png";
import services from "../../assets/services.png";
import resources from "../../assets/resource.png"
import hoursImg from "../../assets/hours.png"
import { CreateService } from './CreateService';
import { CreateResource } from "./CreateResource";
import OperatingHoursForm from './OperatingHoursForm';
import { addDoc, collection, serverTimestamp, updateDoc, doc } from "firebase/firestore";
import { db } from "../../config/firebase";
import { Roles } from "./roles";
import { Countries } from "./countries";
import { toast } from 'react-toastify';


import { DashboardContext } from "./Dashboard";
import { LogoUploader } from '../../components/LogoUploader';
import { WaitlistSettings } from './WaitlistSettings';

function useDashboardContext() {
  let context = useContext(DashboardContext);
  // If context is undefined, we know we used RadioGroupItem
  // outside of our provider so we can throw a more helpful
  // error!
  if (context === undefined) {
    throw Error(
      "DashboardContext must be used inside of Dashboard component, " +
        "otherwise it will not function correctly."
    );
  }

  // Because of TypeScript's type narrowing, if we make it past
  // the error the compiler knows that context is always defined
  // at this point, so we don't need to do any conditional
  // checking on its values when we use this hook!
  return context;
}

export const Settings = () => {

  const { business, user } = useDashboardContext();

  type Service = {
    sname: string;
    minutes: string;
  };

  type Resource = {
    rname: string;
    color: string;
  };

  type Hours = {
    open: string;
    close: string;
    days: string[];
  }
  
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState(business.formData);
  const [serviceData, setServiceData] = useState<Service[]>(business.serviceData);
  const [resourceData, setResourceData] = useState<Resource[]>(business.resourceData);
  const [hours, setHours] = useState<Hours>(business.hours);
  const [bnameError, setBnameError] = useState('');
  const [categoryError, setCategoryError] = useState('');
  const [slugError, setSlugError] = useState('');
  const [roleError, setRoleError] = useState('');
  const [websiteError, setWebsiteError] = useState('');
  const [setting, setSetting] = useState('Onboarding');

  const handleChange = (e : any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
  }

  const handleAddService = (service: any) => {
    setServiceData([...serviceData, service])
  }

  const handleRemoveService = (id: any) => {
    setServiceData(serviceData.filter((item, index) => index !== id));
  }

  const handleAddResource = (resource: any) => {
    setResourceData([...resourceData, resource])
  }

  const handleRemoveResource = (id: any) => {
    setResourceData(resourceData.filter((item, index) => index !== id));
  }

  const businessRef = doc(db, "business", business.id);

  const handleLastStep = async () => {
    let formIsValid = true;
    // Reset the error messages
    setBnameError('');
    setCategoryError('');
    setSlugError('');
    setRoleError('');
    setWebsiteError('');
    // Validate the form inputs
    if (!formData.bname) {
      setBnameError("Please enter your organization's name");
      formIsValid = false;
    }
    if (!formData.category) {
      setCategoryError('please select a business category');
      formIsValid = false;
    } 
    if (!formData.slug) {
      setSlugError('Please enter a slug for your business');
      formIsValid = false;
    } else if(!/^\w+$/.test(formData.slug)) {
      setSlugError('Slug can only contain letters and numbers');
      formIsValid = false;
    } else if (formData.slug) {
      if(formData.slug === "dashboard" || formData.slug === "pricing" || formData.slug === "contact" || formData.slug === "login"){
        setSlugError('Slug is not available, select another slug');
        formIsValid = false;
      }
    }
    if(formIsValid) {
      const doc = await updateDoc(businessRef, {
        formData,
        serviceData,
        resourceData,
        hours
      });
      business.formData = formData;
      business.serviceData = serviceData;
      business.resourceData = resourceData;
      business.hours = hours;
      toast.success('Updated Details', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const settingChange = (e: any) => {
    setSetting(e.target.value);
  }

  function handleUploadSuccess(url: string): void {
    throw new Error('Function not implemented.');
  }

  return (
    <div className="decoration-neutral-50">

      <div className="m-auto max-w-md flex flex-col justify-around mt-10">
        <label htmlFor="Settings" className="block mb-2 text-base font-medium text-gray-900 dark:text-gray-400">Settings</label>
        <select id="Settings" className="appearance-none block py-3 px-4 w-full text-base text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 bg-[url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAh0lEQVQ4T93TMQrCUAzG8V9x8QziiYSuXdzFC7h4AcELOPQAdXYovZCHEATlgQV5GFTe1ozJlz/kS1IpjKqw3wQBVyy++JI0y1GTe7DCBbMAckeNIQKk/BanALBB+16LtnDELoMcsM/BESDlz2heDR3WePwKSLo5eoxz3z6NNcFD+vu3ij14Aqz/DxGbKB7CAAAAAElFTkSuQmCC')] bg-no-repeat bg-right bg-origin-content" value={setting} onChange={settingChange}>
          <option value="Onboarding">Onboarding</option>
          <option value="Waitlist">Waitlist</option>
        </select>
      </div>

      {setting === "Onboarding" && (
      <>
      <div className="m-auto max-w-4xl flex flex-col justify-around mt-10">
        <div>
          <div className="flex flex-col justify-center justify-items-center sm:flex-row">
            <span className="m-auto sm:my-auto sm:mx-0 sm:mr-3">
              <img src={businessimg} alt="Contact us mail box" width="52" height="36"/>
            </span>
            <h2 className="my-auto text-4xl font-semibold leading-snug text-center">Business Details</h2>
          </div>
          <p className="p-3 text-lg font-light leading-8"></p>
        </div>
        <form className="grid grid-cols-1 gap-4 p-5 md:grid-cols-2 lg:max-w-4xl" onSubmit={handleSubmit}>
          <div className="flex flex-col">
            <label className="mb-2 font-light" htmlFor="bname">Business name *</label>
            <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder="Ex: SunsetBlu" id="bname" name="bname" value={formData.bname} onChange={handleChange}/>
            {bnameError && <div className="text-red-500" style={{ color: 'red' }}>{bnameError}</div>}
          </div>
          <div className="flex flex-col">
            <label className="mb-2 font-light" htmlFor="category">Business category *</label>
            <select className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 bg-[url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAh0lEQVQ4T93TMQrCUAzG8V9x8QziiYSuXdzFC7h4AcELOPQAdXYovZCHEATlgQV5GFTe1ozJlz/kS1IpjKqw3wQBVyy++JI0y1GTe7DCBbMAckeNIQKk/BanALBB+16LtnDELoMcsM/BESDlz2heDR3WePwKSLo5eoxz3z6NNcFD+vu3ij14Aqz/DxGbKB7CAAAAAElFTkSuQmCC')] bg-no-repeat bg-right bg-origin-content" id="category" name="category" value={formData.category} onChange={handleChange}>
              <option value="">Select a category</option>
              <option value="ANIMAL" >Animal &amp; Pet</option>
              <option value="APP" >App</option>
              <option value="AUTOMOTIVE" >Automotive</option>
              <option value="FINANCE" >Banking &amp; Finance</option>
              <option value="BAR" >Bar &amp; Nightclub</option>
              <option value="SALON" >Beauty salon</option>
              <option value="CAFE" >Cafe</option>
              <option value="DENTAL" >Dental office</option>
              <option value="DOCTOR" >Doctor &amp; Hospital</option>
              <option value="ENTERTAINMENT" >Entertainment</option>
              <option value="EVENT" >Events</option>
              <option value="GOVERNMENT" >Government</option>
              <option value="HAIRDRESSER" >Hair &amp; Barber</option>
              <option value="LODGING" >Housing &amp; Lodging</option>
              <option value="INDIVIDUAL" >Individual</option>
              <option value="IT" >IT Service</option>
              <option value="LEGAL" >Legal Services</option>
              <option value="LOGISTICS" >Logistics</option>
              <option value="OTHER" >Other</option>
              <option value="REAL-ESTATE" >Real estate</option>
              <option value="RENTAL" >Rentals</option>
              <option value="RESTAURANT" >Restaurant</option>
              <option value="SCHOOL" >School &amp; University</option>
              <option value="SPA" >Spa &amp; Massage</option>
              <option value="RETAIL" >Store &amp; Retail</option>
              <option value="HEALTH" >Training &amp; Gym</option>
              <option value="TRAVEL" >Travel &amp; Tourism</option>
            </select>
            {categoryError && <div className="text-red-500" style={{ color: 'red' }}>{categoryError}</div>}
          </div>
          <div className="flex flex-col">
            <label className="mb-2 font-light" htmlFor="plink">Public link</label>
            <div className="appearance-none block w-full bg-gray-100 opacity-50 text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
              <span>
                {window.location.origin}/{formData.slug}
              </span>
            </div>
            {slugError && <div className="text-red-500" style={{ color: 'red' }}>{slugError}</div>}
          </div>
          <div className="flex flex-col max-w">
            <label className="mb-2 font-light" htmlFor="country">Country *</label>
            <select className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 bg-[url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAh0lEQVQ4T93TMQrCUAzG8V9x8QziiYSuXdzFC7h4AcELOPQAdXYovZCHEATlgQV5GFTe1ozJlz/kS1IpjKqw3wQBVyy++JI0y1GTe7DCBbMAckeNIQKk/BanALBB+16LtnDELoMcsM/BESDlz2heDR3WePwKSLo5eoxz3z6NNcFD+vu3ij14Aqz/DxGbKB7CAAAAAElFTkSuQmCC')] bg-no-repeat bg-right bg-origin-content" id="country" name="country" value={formData.country} onChange={handleChange}>
              {
                Countries.map((country, index) => {
                  return <option key={index} value={country.value}>{country.title}</option>
                })
              }
            </select>
          </div>
          <div className="flex flex-col max-w">
            <label className="mb-2 font-light" htmlFor="website">Company website (optional)</label>
            <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder="www.acme.com" id="website" name="website" value={formData.website} onChange={handleChange}/>
            {websiteError && <div className="text-red-500" style={{ color: 'red' }}>{websiteError}</div>}
          </div>
        </form>
      </div>

      <div className="m-auto max-w-4xl flex flex-col justify-around mt-10">
        <div>
          <div className="flex flex-col justify-center justify-items-center sm:flex-row">
            <span className="m-auto sm:my-auto sm:mx-0 sm:mr-3">
              <img src={services} alt="services" width="52" height="36"/>
            </span>
            <h2 className="my-auto text-4xl font-semibold leading-snug text-center">Offered services</h2>
          </div>
          <p className="p-3 text-lg font-light leading-8 text-center">Select from this list or add your own.</p>
        </div>
        <div className="flex justify-center flex-wrap">
          {
            serviceData.map((service, index) => {
              return (
                <div key={index} className="w-60 h-30 p-8 m-5 rounded-2xl border flex flex-wrap items-center">
                  <div>
                    <div className="mx-2">{service.sname}</div>
                    <div className="mx-2">{service.minutes? service.minutes+'m': null}</div>
                  </div>
                  <div className="flex border rounded-full w-12 h-12 ml-auto text-center items-center justify-center hover:shadow-lg hover:border-gray-500" onClick={() => {handleRemoveService(index)}}>
                    <svg
                      className="block rotate-45"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.00004 4.00605e-05L8.00014 15.9999"
                        stroke="#86868B"
                      ></path>
                      <path d="M0.000131098 7.99987L16 8.00009" stroke="#86868B"></path>
                    </svg>
                  </div>
                </div>
              );
            })
          }
          <CreateService handleAddService={handleAddService}/>
        </div>
      </div>

      <div className="m-auto max-w-4xl flex flex-col justify-around mt-10">
        <div>
          <div className="flex flex-col justify-center justify-items-center sm:flex-row">
            <span className="m-auto sm:my-auto sm:mx-0 sm:mr-3">
              <img src={resources} alt="resources" width="52" height="36"/>
            </span>
            <h2 className="my-auto text-4xl font-semibold leading-snug text-center">Available resources</h2>
          </div>
          <p className="p-3 text-lg font-light leading-8 text-center">Select from this list or add your own.</p>
        </div>
        <div className="flex justify-center flex-wrap">
          {
            resourceData.map((resource, index) => {
              return (
                <div key={index} className="w-60 h-30 p-8 m-5 rounded-2xl border flex flex-wrap items-center">
                  <div className="flex">
                    <div className="mx-2 w-6 h-6 rounded-full" style={{ backgroundColor: `${resource.color? resource.color: "#0000ff"}`}}></div>
                    <div className="mx-2">{resource.rname}</div>
                  </div>
                  <div className="flex border rounded-full w-12 h-12 ml-auto text-center items-center justify-center hover:shadow-lg hover:border-gray-500" onClick={() => {handleRemoveResource(index)}}>
                    <svg
                      className="block rotate-45"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.00004 4.00605e-05L8.00014 15.9999"
                        stroke="#86868B"
                      ></path>
                      <path d="M0.000131098 7.99987L16 8.00009" stroke="#86868B"></path>
                    </svg>
                  </div>
                </div>
              );
            })
          }
          <CreateResource handleAddResource={handleAddResource}/>
        </div>
      </div>

      <div className="m-auto max-w-4xl flex flex-col justify-around mt-10">
        <div>
          <div className="flex flex-col justify-center justify-items-center sm:flex-row">
            <span className="m-auto sm:my-auto sm:mx-0 sm:mr-3">
              <img src={hoursImg} alt="hours" width="52" height="36"/>
            </span>
            <h2 className="my-auto text-4xl font-semibold leading-snug text-center">Add your hours of operation.</h2>
          </div>
        </div>
        <OperatingHoursForm handleLastStep={handleLastStep} hours={hours} setHours={setHours}/>
      </div>
      </>
    )
  }
  {setting === "Waitlist" && (
    <WaitlistSettings />
  )}

  </div>
  )
}