import mail from "../assets/mail.webp";
import { useState, useEffect } from "react";
import { Loading } from "../components/Loading";

export const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    // Add state variables for error messages
    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [messageError, setMessageError] = useState('');
    const [submissionAllowed, setSubmissionAllowed] = useState(true);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      const lastSubmissionTimestamp = localStorage.getItem('lastFormSubmissionTimestamp');
      if (lastSubmissionTimestamp) {
          const timeSinceLastSubmission = Date.now() - +lastSubmissionTimestamp;
          if (timeSinceLastSubmission < 60000) { // 1 minute
              setSubmissionAllowed(false);
          } else {
              setSubmissionAllowed(true);
          }
      }
    }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    // Reset the error messages
    setNameError('');
    setEmailError('');
    setMessageError('');
    // Validate the form inputs
    let formIsValid = true;
    if (!name) {
        setNameError('Please enter your name');
        formIsValid = false;
    }
    if (!email) {
        setEmailError('Please enter your email');
        formIsValid = false;
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        setEmailError('Please enter a valid email');
        formIsValid = false;
    }
    if (!message) {
        setMessageError('Please enter a message');
        formIsValid = false;
    }
    if (formIsValid) {
      // Prepare the data to be sent to Firebase
      if (submissionAllowed) {
        const data = { name, email, message };
        setLoading(true);
        try {
          const res = await fetch("https://script.google.com/macros/s/AKfycbwSlEBM-OyyiMJF1Jln9HxCj8lVU9MyIENUFX_iyVKKJv917rmDQtaeqhj0q9OAQrIX/exec", {
              method: "POST",
              mode: 'no-cors',
              headers: {
                'Accept': 'application/json, text/plain, */*',
                "Content-Type": "application/json"
              },
              body: JSON.stringify(data)
          });
          if (res.status === 0) {
              setLoading(false);
              setName('');
              setEmail('');
              setMessage('');
          } else {
            setLoading(false);
            alert("An error occurred while submitting the form. Please try again later.");
          }
        } catch (err) {
            setLoading(false);
            console.error(err);
            alert("An error occurred while submitting the form. Please try again later.");
        }

        localStorage.setItem('lastFormSubmissionTimestamp', Date.now().toString());
      } else {
          alert('You can only submit the form once every minute. Please try again later.');
      }

    }
  }

  return (
    <div className="mb-auto">
      <div>
        <div className="decoration-neutral-50">
          <div className="m-auto max-w-lg">
            <div className="flex justify-center">
              <span className="my-auto mr-3">
                <img src={mail} alt="Contact us mail box" width="52" height="36"/>
              </span>
              <h2 className="mb-5 text-4xl font-semibold leading-snug text-center">Contact us</h2>
            </div>
            <p className="p-3 text-lg font-light leading-8">Do you have a question about pager? A cool feature you'd like us to integrate? A bug to report? Don't hesitate!</p>
          </div>
          <form className="grid grid-cols-1 gap-4 p-5 m-auto max-w-lg md:grid-cols-2 lg:max-w-2xl" onSubmit={handleSubmit}>
            <div className="flex flex-col max-w-xs">
              <label className="mb-2 font-light" htmlFor="name">Your Name</label>
              <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder="Enter your name" id="name" value={name} onChange={(e) => setName(e.target.value)}/>
              {nameError && <div className="text-red-500" style={{ color: 'red' }}>{nameError}</div>}
            </div>
            <div className="flex flex-col max-w-xs">
              <label className="mb-2 font-light" htmlFor="email">Your email</label>
              <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="email" placeholder="Enter your email address" id="email" value={email} onChange={(e) => setEmail(e.target.value)}/>
              {emailError && <div className="text-red-500" style={{ color: 'red' }}>{emailError}</div>}
            </div>
            <div className="flex flex-col col-span-full max-w-2xs">
              <label className="mb-2 font-light" htmlFor="message">Message</label>
              <textarea className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" placeholder="Enter your message here..." id="message" rows={5} value={message} onChange={(e) => setMessage(e.target.value)}/>
              {messageError && <div className="text-red-500" style={{ color: 'red' }}>{messageError}</div>}
            </div>
            <button className="text-xl col-span-full inline-flex mx-auto px-6 bg-gray-500 my-auto py-2 rounded-lg text-center text-neutral-50 hover:bg-gray-600" disabled={!submissionAllowed}>
              Submit
              <span className="inline-flex ml-2 my-auto"><svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></span>
            </button>
          </form>
          {loading? <Loading /> : null}
        </div>
      </div>
    </div>
  )
}