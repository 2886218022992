import React, { useState, useEffect } from "react";
import { auth, db } from "../../config/firebase";
import { collection, getDocs, doc, query, where, onSnapshot, Unsubscribe, orderBy } from "firebase/firestore";
import { Loading } from "../Loading";


interface Pager {
  id: string;
  dateCreated: Date;
  name: string,
  phone: string,
  notes: string,
  assignedToResource: string;
  assignedToService: string;
  status: string;
}

interface PagerProps {
  slug: string,
  id: string;
}

interface Business {
  id: string,
  slug : string,
  name: string,
  website: string,
  opensAt: string,
  closesAt: string,
  days: string[],
  country: string
}

export const Buzzer = (props : PagerProps) => {
  const [pager, setPager] = useState<Pager>({id: '', dateCreated: new Date(0), name: '', phone: '', notes: '', assignedToResource: '', assignedToService: '', status: ''});
  const [pagers, setPagers] = useState<Pager[]>([]);
  const [notFound, setNotFound] = useState(false);
  const [business, setBusiness] = useState<Business | null>(null);
  const [completionPercentage, setCompletionPercentage] = useState<number>(0);
  const [currentPosition, setCurrentPosition] = useState<number>(0);


  useEffect(() => {
    const fetchBusiness = async () => {
      const businessRef = collection(db, "business");
      const qry = query(businessRef, where("formData.slug", "in", [props.slug]));
      const snapshot = await getDocs(qry);
      if (snapshot.size > 0) {
        const bdata = { 
          id: snapshot.docs[0].id,
          slug: snapshot.docs[0].data().formData.slug,
          name: snapshot.docs[0].data().formData.bname,
          website: snapshot.docs[0].data().formData.website,
          opensAt: snapshot.docs[0].data().hours.open,
          closesAt: snapshot.docs[0].data().hours.close,
          days: snapshot.docs[0].data().hours.days,
          country: snapshot.docs[0].data().formData.country
        } as Business;
        setBusiness(bdata);
      }
    }
    
    let unsubscribe: Unsubscribe | null = null;
    let unsubscribepagers: Unsubscribe | null = null;

    if (!business) {
      fetchBusiness();
    } else if(pager.id === '') {
      const pagerRef = doc(db, "business", business.id, "pagers", props.id);
      unsubscribe = onSnapshot(pagerRef, (doc) => {
        if (doc.exists()) {
          const fetchedPager = { id: doc.id, ...doc.data() } as Pager;
          setPager(fetchedPager);
        } else {
          setNotFound(true);
        }
      }, (error) => {
        setNotFound(true);
      });
    } else {
      const pagers = query(collection(db, "business", business.id, "pagers"), orderBy("dateCreated", "desc"));
      unsubscribepagers = onSnapshot(pagers, (querySnapshot) => {
        const pagers = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as Pager[];
        setPagers(pagers);
        const index =  pagers.findIndex((p) => p.id === pager.id);
        if (index === -1) {
          setPager({...pager, status: "served"});
        } else {
          setCurrentPosition(pagers.length - pagers.findIndex((p) => p.id === pager.id));
          setCompletionPercentage(((currentPosition-1)/pagers.length)*100);
        }
      });
    }

      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
        if(unsubscribepagers) {
          unsubscribepagers();
        }
      };
  }, [props.id, props.slug, pager, business, currentPosition]);

  if(notFound) {
    return <h1>404</h1>
  }
  
  if (!pager.id) {
    return <Loading />;
  }

  const circumference = 2 * 22 / 7 * 70;

  return (
    <div className="flex flex-col justify-center font-['Poppins'] max-w-md m-auto">
      <div className="text-[2.0rem] text-center mt-5">{business?.name}</div>
      {currentPosition > 2 ? 
      (<>
        <div className="flex justify-center items-center">
        <svg className="transform -rotate-90 w-72 h-72">
            <circle cx="145" cy="145" r="70" stroke="currentColor" strokeWidth="5" fill="transparent"
                className="text-green-500" />

            <circle cx="145" cy="145" r="70" stroke="currentColor" strokeWidth="5" fill="transparent"
                strokeDasharray={circumference}
                strokeDashoffset={circumference * (completionPercentage / 100)}
                className="text-gray-500" />
        </svg>
        <svg className="absolute fill-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48" data-cy="icon-check"><title>Check</title><path d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"></path></svg>
        </div>
          <div className="flex flex-col text-center items-center gap-x-6 gap-y-6 mb-8">
          <h1 className="text-[2.0rem]">Thanks for waiting!</h1>
          <p className="text-[1.0rem]">Stay on this page to get notified when it's your turn.</p>
        </div>
      </>) : (<>
        <div className="flex justify-center items-center">
          <svg className="transform -rotate-90 w-72 h-72">
              <circle cx="145" cy="145" r="70" stroke="currentColor" strokeWidth="5" fill="transparent"
                  className="text-orange-500" />
          </svg>
          <svg className="absolute fill-orange-500 animate-bounce" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48" data-cy="icon-bell"><title>Bell</title><path d="M12 22a2.98 2.98 0 0 0 2.818-2H9.182A2.98 2.98 0 0 0 12 22zm7-7.414V10c0-3.217-2.185-5.927-5.145-6.742C13.562 2.52 12.846 2 12 2s-1.562.52-1.855 1.258C7.185 4.074 5 6.783 5 10v4.586l-1.707 1.707A.996.996 0 0 0 3 17v1a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-1a.996.996 0 0 0-.293-.707L19 14.586z"></path></svg>
        </div>
        <div className="flex flex-col text-center items-center gap-x-6 gap-y-6 mb-8">
          <h1 className="text-[2.0rem]">You're next in line!</h1>
          <p className="text-[1.0rem]">Start making your way back to {business?.name}</p>
        </div>
        </>)
        }

      <div>
        <div className="flex flex-col text-center mb-8">
          <div>
            <p className="uppercase text-lg text-[#767676]">Place in line</p>
          </div>
          <p className="text-3xl font-semibold">{currentPosition}</p>
        </div>
      </div>
      <div className="border-t-2 border-b-2 mb-8">
        <div className="flex flex-col gap-x-3 gap-y-3 mt-8 mb-8">        
            <div> 
              <div className="text-[#767676]">Name</div> 
              <div className="text-lg	first-letter:uppercase">{pager.name}</div>
            </div>
            {pager.assignedToResource? <div> 
              <div className="text-[#767676]">Assigned To</div> 
              <div className="text-lg first-letter:uppercase">{pager.assignedToResource}</div>
            </div> : null}
            {pager.assignedToService? <div> 
              <div className="text-[#767676]">Service</div> 
              <div className="text-lg first-letter:uppercase">{pager.assignedToService}</div>
            </div> : null}
            {pager.status? <div> 
              <div className="text-[#767676]">Status</div> 
              <div className="text-lg first-letter:uppercase">{pager.status}</div>
            </div> : null}
            
        </div>
      </div>
    </div>
  ); 
}