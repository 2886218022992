import addToList from "../../assets/add_to_list.svg";
import { CreatePager } from "./CreatePager";
import React, { useState, useContext, useEffect } from "react";
import { DashboardContext } from "./Dashboard";
import { auth, db } from "../../config/firebase";
import { collection, getDocs, addDoc, serverTimestamp, deleteDoc, doc, orderBy, query } from "firebase/firestore";
import { Loading } from "../../components/Loading";
import { PagerEntry } from "./PagerEntry";


interface Pager {
  id: string;
  dateCreated: Date;
  name: string,
  phone: string,
  notes: string,
  assignedToResource: string;
  assignedToService: string;
  status: string;
  url: string;
}

function useDashboardContext() {
  let context = useContext(DashboardContext);
  // If context is undefined, we know we used RadioGroupItem
  // outside of our provider so we can throw a more helpful
  // error!
  if (context === undefined) {
    throw Error(
      "DashboardContext must be used inside of Dashboard component, " +
        "otherwise it will not function correctly."
    );
  }

  // Because of TypeScript's type narrowing, if we make it past
  // the error the compiler knows that context is always defined
  // at this point, so we don't need to do any conditional
  // checking on its values when we use this hook!
  return context;
}

export const List = () => {
  const [pagers, setPagers] = useState<Pager[]>([]);

  const [loading, setLoading] = useState(false);

  const [initialLoading, setInitialLoading] = useState(true);


  const { business } = useDashboardContext();


  useEffect(() => {
    const fetchData = async () => {
      const pagersRef = query(collection(db, "business", business.id, "pagers"), orderBy("dateCreated"));
      setInitialLoading(true);
      const snapshot = await getDocs(pagersRef);
      const fetchedPagers = snapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data(), dateCreated: new Date(doc.data().dateCreated.toDate()), url: `${window.location.origin}/${business.formData.slug}/${doc.id}` } as Pager;
      });
      setInitialLoading(false);
      setPagers(fetchedPagers);
    };
    if(business.id) {
      fetchData();
    }
  }, [business.id]);

  const addPager = async (
    name: string,
    phone: string,
    notes: string,
    assignedToResource: string,
    assignedToService: string
  ) => {
    const pagersRef = collection(db, "business", business.id, "pagers");
    setLoading(true);
    const newPager = await addDoc(pagersRef, {
      dateCreated: serverTimestamp(),
      name,
      phone,
      notes,
      assignedToResource,
      assignedToService,
      status: "active"
    });
    setLoading(false);
    setPagers((prevPagers) => [...prevPagers, {id: newPager.id, dateCreated: new Date(), name, phone, notes, assignedToResource, assignedToService, status: "active", url: `${window.location.origin}/${business.formData.slug}/${newPager.id}`}]);
  };

  const removePager = async (pager: Pager) => {
    const id = pager.id;
    const now = new Date();
    const currentDate = now.toISOString().substring(0, 10);
    const docRef = doc(db, "business", business.id, "pagers", id);
    const pagerAuditRef = collection(db, "business", business.id, "pagerAudit", currentDate, "entries");
    setLoading(true);
    await deleteDoc(docRef);
    await addDoc(pagerAuditRef, {
      ...pager, status: "Removed", dateRemoved: serverTimestamp()
    });
    setLoading(false);
    setPagers((prevPagers) => prevPagers.filter((pager) => pager.id !== id));
  };

  const sortPagersByDate = (): void => {
    setPagers((prevPagers) =>
      prevPagers.sort((a, b) => b.dateCreated.getTime() - a.dateCreated.getTime())
    );
  };

  const groupPagersByService = (pagers: Pager[]): { [key: string]: Pager[] } =>
  pagers.reduce((acc:any, pager) => {
    const key = pager.assignedToService;
    acc[key] = acc[key] ? [...acc[key], pager] : [pager];
    return acc;
  }, {});

  const groupPagersByResource = (pagers: Pager[]): { [key: string]: Pager[] } =>
    pagers.reduce((acc:any, pager) => {
      const key = pager.assignedToResource;
      acc[key] = acc[key] ? [...acc[key], pager] : [pager];
      return acc;
    }, {});

  const sharePager = (pager: Pager) => {

  }

  const markServed = async (pager: Pager) => {
    const id = pager.id;
    const now = new Date();
    const currentDate = now.toISOString().substring(0, 10);
    const docRef = doc(db, "business", business.id, "pagers", id);
    const pagerAuditRef = collection(db, "business", business.id, "pagerAudit", currentDate, "entries");
    setLoading(true);
    await deleteDoc(docRef);
    await addDoc(pagerAuditRef, {
      ...pager, status: "Served", dateRemoved: serverTimestamp()
    });
    setLoading(false);
    setPagers((prevPagers) => prevPagers.filter((pager) => pager.id !== id));
  }

  const sendAlert = (pager: Pager) => {
    
  }

  const [groupBy, setGroupBy] = useState<"resource" | "service">("service");
  const groupedPagers =
    groupBy === "service"
      ? groupPagersByService(pagers)
      : groupPagersByResource(pagers);


  return (
    <>
            {initialLoading? <Loading /> : (Array.isArray(pagers) && pagers.length === 0) ? (<div className="flex flex-col items-center justify-center p-20">
              <img src={addToList} alt="Your waitlist is empty. Add a Buzzerr to get started." width={250}/>
              <h5 className="text-center mt-10">No Buzzers Active</h5>
              <p className="text-center">Add a Buzzer to get started</p>
              <CreatePager addPager={addPager} />
            </div>) : (
              <div className="container mx-auto p-4">
                  <div className="flex items-center justify-between relative">
                      <p tabIndex={0} className="focus:outline-none text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-800">Buzzer List</p>
                      <div className="py-3 px-4 flex items-center text-sm font-medium leading-none text-gray-600 bg-gray-200 hover:bg-gray-300 cursor-pointer rounded">
                          <p>Group By:</p>
                          <select aria-label="select" className="focus:text-fuchsia-900 focus:outline-none bg-transparent ml-1" onChange={
                            (e) =>{
                              if(e.target.value === "Service"){
                                setGroupBy("service");
                              } else if(e.target.value === "Resource") {
                                setGroupBy("resource");
                              }
                            } 
                          }>
                              <option className="text-sm text-indigo-800">Service</option>
                              <option className="text-sm text-indigo-800">Resource</option>
                          </select>
                      </div>
                  </div>
                  <div className="mt-4">
                  {Object.entries(groupedPagers).map(([key, pagers]) => (
                    <div key={key} className="mb-4">
                      <h2 className="text-lg font-bold mb-2 first-letter:uppercase">{key}</h2>
                        <ul className="space-y-4">
                            {pagers.map((pager) => (
                              <PagerEntry pager={pager} sharePager={sharePager} removePager={removePager} markServed={markServed} sendAlert={sendAlert}/>
                            ))}
                        </ul>
                    </div>
                  ))}
                  <CreatePager addPager={addPager} />
                    {/* <button className="fixed bottom-10 right-10 p-3 rounded-full bg-blue-500 text-white shadow-lg hover:bg-blue-600 focus:outline-none">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                      </svg>
                    </button> */}
                </div>
              </div>
            )
          }
          {loading? <Loading /> : null}
    </>
  )
}
