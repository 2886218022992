import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { auth, db } from "../../config/firebase";
import { collection, getDocs, query, where, onSnapshot, orderBy } from "firebase/firestore";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './Waitlist.css';
import { WaitlistItem } from "./WaitlistItem";
import FullscreenButton from './FullscreenButton';
import { formatDistanceToNow } from "date-fns";
import { useAuthState } from "react-firebase-hooks/auth";
import { Loading } from "../Loading";

interface Business {
  id: string,
  ownerId: string,
  slug : string,
  name: string,
  website: string,
  opensAt: string,
  closesAt: string,
  days: string[],
  country: string
}

type WaitlistItemType = {
  dateCreated: Date;
  name: string,
  phone: string,
  notes: string,
  assignedToResource: string;
  assignedToService: string;
  status: string;
}

export const Waitlist = (props: any) => {
  // generate code to show active list of buzzers for the slug provided in the URL
  // if no slug is provided, show a list of all active buzzers
  // if no active buzzers are found, show a message
  // if no slug is provided and no active buzzers are found, show a message
  const [business, setBusiness] = useState<Business | null>(null);
  const [waitlist, setWaitlist] = useState<WaitlistItemType[]>([]);
  const [lastUpdateTime, setLastUpdateTime] = useState<Date>(new Date());
  const [lastUpdateTimeString, setLastUpdateTimeString] = useState<string>("");
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [user] = useAuthState(auth);
  const [isLoaded, setIsLoaded] = useState(false);
  const nodeRef = useRef(null);

  const [settings, setSettings] = useState<Settings>({
    LogoUrl: "",
    Title: "",
    Description: "Welcome to our waitlist! This page shows the total of users waiting in line for our services.",
    ShowTimeElapsed: true,
    ShowNumberOfUsersInWaitlist: true,
    enableSelfSignup: false,
    isPrivateWaitlist: false,
    showWaitTime: true,  
    groupby: "NONE",
    sort: "DESC",
    backgroundColor: "#64F0C1",
    textColor: "#000000"
  });

  type Settings = {
    LogoUrl: string;
    Title: string;
    Description: string;
    ShowTimeElapsed: boolean;
    ShowNumberOfUsersInWaitlist: boolean;
    enableSelfSignup: boolean;
    isPrivateWaitlist: boolean;
    showWaitTime: boolean;
    groupby: string;
    sort: string;
    backgroundColor: string;
    textColor: string;
  }

  useEffect(() => {
    const fetchBusiness = async () => {
      const businessRef = collection(db, "business");
      const qry = query(businessRef, where("formData.slug", "in", [props.slug]));
      const snapshot = await getDocs(qry);
      if (snapshot.size > 0) {
        const bdata = {
          id: snapshot.docs[0].id,
          ownerId: snapshot.docs[0].data().userId,
          slug: snapshot.docs[0].data().formData.slug,
          name: snapshot.docs[0].data().formData.bname,
          website: snapshot.docs[0].data().formData.website,
          opensAt: snapshot.docs[0].data().hours.open,
          closesAt: snapshot.docs[0].data().hours.close,
          days: snapshot.docs[0].data().hours.days,
          country: snapshot.docs[0].data().formData.country
        } as Business; 
        setBusiness(bdata);
        const settings = snapshot.docs[0].data().waitListSettings as Settings;
        if (settings) {
          setSettings(settings);
        }
      }
    }

    if(!business) {
      fetchBusiness();
    } else if(settings && (!settings.isPrivateWaitlist || (settings.isPrivateWaitlist && user?.uid === business.ownerId))) {
      let order;
      switch (settings.sort) {
        case "DESC":
          order = orderBy("dateCreated", "asc");
          break;
        case "ASC":
          order = orderBy("dateCreated", "desc");
          break;
        case "ALPHA":
          order = orderBy("name");
          break;
        default:
          order = orderBy("dateCreated", "asc");
          break;
      }
      const pagers = query(collection(db, "business", business.id, "pagers"), order);
      const unsubscribe = onSnapshot(pagers, (snapshot) => {
        const newWaitlist: WaitlistItemType[] = snapshot.docs.map((doc) => ({
          dateCreated: new Date(doc.data().dateCreated.toDate()),
          name: doc.data().name,
          phone: doc.data().phone,
          notes: doc.data().notes,
          assignedToResource: doc.data().assignedToResource,
          assignedToService: doc.data().assignedToService,
          status: doc.data().status
        }));
        setWaitlist(newWaitlist);
        setLastUpdateTime(new Date());
      });
      setIsLoaded(true);
      return unsubscribe;
    } else {
      setIsLoaded(true);
    }
  }, [props.slug, business, settings, user]);

  const handleFullscreenClick = () => {
    if (!isFullscreen) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
    setIsFullscreen(!isFullscreen);
  };

  useEffect(() => {
    setLastUpdateTimeString(formatDistanceToNow(lastUpdateTime, { addSuffix: true }));
    const intervalId = setInterval(() => {
      setLastUpdateTimeString(formatDistanceToNow(lastUpdateTime, { addSuffix: true }));
    }, 30000);
    return () => clearInterval(intervalId);
  }, [lastUpdateTime]);

  const groupPagersByService = (pagers: WaitlistItemType[]): { [key: string]: WaitlistItemType[] } =>
  pagers.reduce((acc:any, pager) => {
    const key = pager.assignedToService;
    acc[key] = acc[key] ? [...acc[key], pager] : [pager];
    return acc;
  }, {});

  const groupPagersByResource = (pagers: WaitlistItemType[]): { [key: string]: WaitlistItemType[] } =>
    pagers.reduce((acc:any, pager) => {
      const key = pager.assignedToResource;
      acc[key] = acc[key] ? [...acc[key], pager] : [pager];
      return acc;
    }, {});
  
  return (
    <>
    {!isLoaded ? ( 
        <Loading /> 
      ): (
        <>
        {(!settings.isPrivateWaitlist || (settings.isPrivateWaitlist && user?.uid === business?.ownerId)) ? (
          <CSSTransition in={isLoaded} nodeRef={nodeRef} classNames="fade" timeout={500}>
          <div className="waitlist flex flex-col max-w-screen-md mx-auto font-['Poppins']" ref={nodeRef}>
            <div className="container">
              <div>
                {settings.LogoUrl && (
                  <img
                    className="mx-auto mb-3"
                    id="header-image"
                    src={settings.LogoUrl}
                    alt="logo"
                    width="400"
                  />)
                }
              </div>
              {settings.Title === "" ? (
                <div className="text-center">
                  <h1 className="text-4xl m-2">{business?.name} Waitlist</h1>
                </div>
              ): (
                <div className="text-center">
                  <h1 className="text-4xl m-2">{settings.Title}</h1>
                </div>
              )}
              {settings.ShowNumberOfUsersInWaitlist && (
                <h2 className="text-center fs-5 m-4">{waitlist.length} Waiting</h2>
              )}
              {settings.ShowTimeElapsed && (
                <h2 className="text-center fs-5 mb-4">Last updated: {lastUpdateTimeString}</h2>
              )}
              <div>
                <div className="col-md-8 offset-md-2 themed p-3 mb-3">
                  <p style={{textAlign: 'center'}}>
                    {settings.Description}
                  </p>
                </div>
              </div>
            </div>
            {settings.groupby === "NONE" && (
              <div className="container">
                <div className="">
                  <TransitionGroup>
                    <ul className="stripey align-middle themed" id="filtered-list">
                      {waitlist.map((item, index) => (
                        <CSSTransition key={item.name} classNames="item" timeout={500}>
                          <WaitlistItem itemNo={index+1} item={item} showWaitTime={settings.showWaitTime} backgroundColor={settings.backgroundColor} textColor={settings.textColor}/>
                        </CSSTransition>
                      ))}
                    </ul>
                  </TransitionGroup>
                </div>
              </div>
            )}

            {settings.groupby === "SERVICE" && (
              <div className="container">
                <div className="">
                  <TransitionGroup>
                    {Object.entries(groupPagersByService(waitlist)).map(([key, value]) => (
                      <div key={key}>
                        <h2 className="text-lg font-bold mb-2 first-letter:uppercase">{key}</h2>
                        <ul className="stripey align-middle themed" id="filtered-list">
                          {value.map((item, index) => (
                            <CSSTransition key={item.name} classNames="item" timeout={500}>
                              <WaitlistItem itemNo={index+1} item={item} showWaitTime={settings.showWaitTime} backgroundColor={settings.backgroundColor} textColor={settings.textColor}/>
                            </CSSTransition>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </TransitionGroup>
                </div>
              </div>
            )}

            {settings.groupby === "RESOURCE" && (
              <div className="container">
                <div className="">
                  <TransitionGroup>
                    {Object.entries(groupPagersByResource(waitlist)).map(([key, value]) => (
                      <div key={key}>
                        <h2 className="text-lg font-bold mb-2 first-letter:uppercase">{key}</h2>
                        <ul className="stripey align-middle themed" id="filtered-list">
                          {value.map((item, index) => (
                            <CSSTransition key={item.name} classNames="item" timeout={500}>
                              <WaitlistItem itemNo={index+1} item={item} showWaitTime={settings.showWaitTime} backgroundColor={settings.backgroundColor} textColor={settings.textColor}/>
                            </CSSTransition>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </TransitionGroup>
                </div>
              </div>
            )}

            <FullscreenButton onClick={handleFullscreenClick} />
          </div>
          </CSSTransition>
        ) : (
          <div className="waitlist flex flex-col m-auto font-['Poppins']">
            <div className="">
              <h3 className="block font-bold mb-10">Login to view waitlist</h3>
              <div className="text-center">
                <Link className="bg-gray-500 hover:bg-gray-400 text-white font-bold py-2 px-4 border-b-4 border-gray-700 hover:border-gray-500 rounded w-full uppercase mt-3 grow" to="/login">Login</Link>
              </div>

            </div>
          </div>
        )}
        </>
      )
    }
  </>
  );
};