export const ServiceForm = ( props: any) => {
  const {onSubmit, handleChange} = props;
  return (
    <div className="mb-auto">
      <div>
        <div className="decoration-neutral-50">
          <p className="text-center">
          Add the services your business offers for clients to choose from when signing up for an appointment or joining your waitlist.
          </p>
          <form className="grid grid-cols-1 gap-4 p-5 m-auto max-w-lg md:grid-cols-2 lg:max-w-2xl" onSubmit={onSubmit}>
            <div className="flex flex-col max-w-xs">
              <label className="mb-2 font-light" htmlFor="sname">Service type</label>
              <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder="Add a service" id="sname" name="sname" onChange={handleChange}/>
            </div>
            <div className="flex flex-col max-w-xs">
              <label className="mb-2 font-light" htmlFor="minutes">Duration in minutes  (optional)</label>
              <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="number" placeholder="e.g 45" id="minutes" name="minutes" onChange={handleChange}/>
            </div>
            <button className="text-xl col-span-full inline-flex mx-auto px-6 bg-blue-500 my-auto py-2 rounded-lg text-center text-neutral-50 hover:bg-blue-700">
              Save
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}