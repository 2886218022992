import { useContext, useState } from "react";
import { LogoUploader } from "../../components/LogoUploader"
import { DashboardContext } from "./Dashboard";
import { toast } from 'react-toastify';
import {getDownloadURL, ref, uploadBytes} from "firebase/storage"
import { db, storage } from "../../config/firebase";
import { Loading } from "../../components/Loading";
import { updateDoc, doc } from "firebase/firestore";
import { ColorPicker } from "./ColorPicker";


function useDashboardContext() {
  let context = useContext(DashboardContext);
  // If context is undefined, we know we used RadioGroupItem
  // outside of our provider so we can throw a more helpful
  // error!
  if (context === undefined) {
    throw Error(
      "DashboardContext must be used inside of Dashboard component, " +
        "otherwise it will not function correctly."
    );
  }

  // Because of TypeScript's type narrowing, if we make it past
  // the error the compiler knows that context is always defined
  // at this point, so we don't need to do any conditional
  // checking on its values when we use this hook!
  return context;
}

export const WaitlistSettings = (props: any) => {

  const { business } = useDashboardContext();

  type Settings = {
    LogoUrl: string;
    Title: string;
    Description: string;
    ShowTimeElapsed: boolean;
    ShowNumberOfUsersInWaitlist: boolean;
    enableSelfSignup: boolean;
    isPrivateWaitlist: boolean;
    showWaitTime: boolean;
    groupby: string;
    sort: string;
    backgroundColor: string;
    textColor: string;
  }

  const [settings, setSettings] = useState<Settings>(business.waitListSettings ?? {
    LogoUrl: "",
    Title: `${business.formData.bname} Waitlist`,
    Description: "Welcome to our waitlist! This page shows the total of users waiting in line for our services.",
    ShowTimeElapsed: true,
    ShowNumberOfUsersInWaitlist: true,
    enableSelfSignup: false,
    isPrivateWaitlist: false,
    showWaitTime: true,  
    groupby: "NONE",
    sort: "DESC",
    backgroundColor: "#64F0C1",
    textColor: "#000000"
  });

  const [logo, setLogo] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);

  const handleTextColorChange = (newColor: string) => {
    setSettings({...settings, 'textColor': newColor});
  };

  const handleBackgroundColorChange = (newColor: string) => {
    setSettings({...settings, 'backgroundColor': newColor});
  };

  const handleChange = (e : any) => {
    const { name, type, checked, value } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setSettings({...settings, [name]: newValue});
  }

  const handleSave = async () => {
    setUploading(true);
    await handleUpload();
  }

  const onUpload = ( logo : File | null) => {
    setLogo(logo);
  }

  const handleUpload = async () => {
    if (logo) {
      const fileExtension = logo.name.split('.').pop();
      const logoRef = ref(storage, `/logos/${business.formData.slug}.${fileExtension}`);

      uploadBytes(logoRef, logo).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((url) => {
          const businessRef = doc(db, "business", business.id);
          updateDoc(businessRef, {
            waitListSettings: {...settings, LogoUrl: url}
          }).then(() => {
            business.waitListSettings = {...settings, LogoUrl: url};
            setSettings({...settings, LogoUrl: url});
            setUploading(false);
            toast.success("Settings saved!");
          }).catch((error: any) => {
            setUploading(false);
            toast.error("Error saving settings");
          });
        });
        setLogo(null);
      }).catch((error) => {
        toast.error("Error uploading logo");
        setUploading(false);
      });
    } else {
      const businessRef = doc(db, "business", business.id);
      updateDoc(businessRef, {
        waitListSettings: settings
      }).then(() => {
        business.waitListSettings = settings;
        setUploading(false);
        toast.success("Settings saved!");
      }).catch((error: any) => {
        setUploading(false);
        toast.error("Error saving settings");
      });
    }

  };
  
  return (
    <div className="m-auto max-w-4xl flex flex-col justify-around mt-10">
      <div className="">
        <div className="">
         <h3 className="text-4xl mb-5">Header image / logo</h3>
          <label>
            Uploading an image here will make it appear at the top of your
            waitlist page.
          </label>
          <LogoUploader onUpload={onUpload} url={settings.LogoUrl}/>

          <hr />
          
          <h3 className="my-5 text-4xl">Title and description</h3>
          <div className="form-group card bg-info">
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mb-5"
              id="Title"
              maxLength={160}
              minLength={0}
              name="Title"
              placeholder="Title"
              type="text"
              value={settings.Title}
              onChange={handleChange}
            />
            <textarea 
              id="Description" 
              name="Description"
              rows={4} 
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mb-5" 
              placeholder="Optional Description here..." 
              value={settings.Description} 
              onChange={handleChange}>
            </textarea>
          </div>

          <hr />
          <h3 className="my-5 text-4xl">Other options</h3>
          <div className="form-check mt-3">
            <label className="relative inline-flex items-center cursor-pointer">
              <input 
                type="checkbox" 
                name="ShowTimeElapsed" 
                className="sr-only peer" 
                checked={settings.ShowTimeElapsed} 
                onChange={handleChange}
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600 mr-2"></div>
              <span className="inline-block pl-[0.15rem] hover:cursor-pointer">Show time elapsed since last update.</span>
            </label>
          </div>
          <div className="form-check mt-3">
            <label className="relative inline-flex items-center cursor-pointer">
              <input 
                type="checkbox" 
                name="ShowNumberOfUsersInWaitlist" 
                className="sr-only peer" 
                checked={settings.ShowNumberOfUsersInWaitlist}
                onChange={handleChange}
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600 mr-2"></div>
              <span className="inline-block pl-[0.15rem] hover:cursor-pointer">Show number of users in waitlist.</span>
            </label>
          </div>
          <div className="form-check mt-3">
            <label className="relative inline-flex items-center cursor-pointer">
              <input 
                type="checkbox" 
                name="enableSelfSignup" 
                className="sr-only peer"
                checked={settings.enableSelfSignup}
                onChange={handleChange}
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600 mr-2"></div>
              <span className="inline-block pl-[0.15rem] hover:cursor-pointer">Enable self Join.</span>
            </label>
          </div>
          <div className="form-check mt-3">
            <label className="relative inline-flex items-center cursor-pointer">
              <input 
                type="checkbox"
                name="isPrivateWaitlist" 
                className="sr-only peer" 
                checked={settings.isPrivateWaitlist}
                onChange={handleChange}
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600 mr-2"></div>
              <span className="inline-block pl-[0.15rem] hover:cursor-pointer">This waitlist is private.</span>
            </label>
          </div>
          <div className="form-check mt-3">
            <label className="relative inline-flex items-center cursor-pointer">
              <input 
                type="checkbox" 
                name="showWaitTime" 
                className="sr-only peer" 
                checked={settings.showWaitTime}
                onChange={handleChange}
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600 mr-2"></div>
              <span className="inline-block pl-[0.15rem] hover:cursor-pointer">Show wait time.</span>
            </label>
          </div>
          <div className="form-group grid grid-cols-1 gap-4 m-auto mt-3 max-w-lg md:grid-cols-2 lg:max-w-4xl">
            <ColorPicker label="Waitlist entry background color" initialValue={settings.backgroundColor} onChange={handleBackgroundColorChange}/>
            <ColorPicker label="Waitlist entry text color" initialValue={settings.textColor} onChange={handleTextColorChange} />
          </div>
          <div className="form-group mt-3">
            <label>Group Waitlist By</label>
            <select className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 bg-[url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAh0lEQVQ4T93TMQrCUAzG8V9x8QziiYSuXdzFC7h4AcELOPQAdXYovZCHEATlgQV5GFTe1ozJlz/kS1IpjKqw3wQBVyy++JI0y1GTe7DCBbMAckeNIQKk/BanALBB+16LtnDELoMcsM/BESDlz2heDR3WePwKSLo5eoxz3z6NNcFD+vu3ij14Aqz/DxGbKB7CAAAAAElFTkSuQmCC')] bg-no-repeat bg-right bg-origin-content" name="groupby" value={settings.groupby} onChange={handleChange}>
              <option value="NONE">
                None
              </option>
              <option value="SERVICE">Service</option>
              <option value="RESOURCE">Resource</option>
            </select>
          </div>
          <div className="form-group my-3 mb-5">
            <label>How should waitlist entries be sorted?</label>
            <select className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 bg-[url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAh0lEQVQ4T93TMQrCUAzG8V9x8QziiYSuXdzFC7h4AcELOPQAdXYovZCHEATlgQV5GFTe1ozJlz/kS1IpjKqw3wQBVyy++JI0y1GTe7DCBbMAckeNIQKk/BanALBB+16LtnDELoMcsM/BESDlz2heDR3WePwKSLo5eoxz3z6NNcFD+vu3ij14Aqz/DxGbKB7CAAAAAElFTkSuQmCC')] bg-no-repeat bg-right bg-origin-content" name="sort" value={settings.sort} onChange={handleChange}>
              <option value="DESC">
                Highest wait time at the top
              </option>
              <option value="ASC">Lowest wait time at the top</option>
              <option value="ALPHA">Alphabetically</option>
            </select>
          </div>

          <hr />
          <button
            type="submit"
            id="save"
            className="bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded w-full uppercase mt-3"
            onClick={handleSave}
          >
            Save
          </button>
          <div className="w-full p-6 bg-white border border-gray-200 rounded-lg shadow my-3">
              <div>
                  <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-900">💡 Good to know</h5>
              </div>
              <p className="mb-3 font-normal text-gray-500">Want to take your waitlist to the next level? Let us know what you're looking for and we'll work with you to make it happen.</p>
              <a href="/contact" className="inline-flex items-center text-blue-600 hover:underline">
                  Contact Us
                  <svg className="w-5 h-5 ml-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"></path><path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"></path></svg>
              </a>
          </div>
        </div>
      </div>
      {uploading? <Loading /> : null}
    </div>
  );
};
