import React, { useState } from 'react';
import { Navbar } from './components/Navbar';
import { Process } from "./components/Process";
import { Footer } from './components/Footer';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "./pages/Home";
import { Pricing } from "./pages/pricing/Pricing";
import { Contact } from "./pages/Contact";
import { Login } from "./pages/Login";
import { Dashboard } from './pages/dashboard/Dashboard';
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function App() {
  const [showNav, setShowNav] = useState(true);
  return (
    <div className="App">
      <div className="flex flex-col justify-between p-5 m-auto max-w-screen-2xl h-screen lg:p-2">
        <Router>
          {showNav && <Navbar/>}
          <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/contact" element={<Contact/>}/>
            <Route path="/pricing" element={<Pricing/>}/>
            <Route path="/login" element={<Login/>}/>
            <Route path="/dashboard" element={<Dashboard/>}/>
            <Route path="/terms" element={<TermsOfService/>}/>
            <Route path="/privacy" element={<PrivacyPolicy/>}/>
            <Route path="/:slug" element={<Process funcNav={setShowNav}/>}/>
            <Route path="/:slug/:id" element={<Process funcNav={setShowNav}/>}/>
          </Routes>
          <Footer/>
        </Router>
      </div>
      <ToastContainer />
    </div>
  );
}

export default App;
