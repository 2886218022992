import { useState } from "react";
import { Modal } from "../../components/Modal";
import { ResourceForm } from "./ResourceForm";

export const CreateResource = (props: any) => {

  const {handleAddResource} = props;

  const [showModal, setShowModal] = useState(false);
  const [resource, setResource] = useState({rname: '', color: ''})

  const handleChange = (e : any) => {
    setResource({ ...resource, [e.target.name]: e.target.value });
  }

  const onSubmit = (event : React.FormEvent) => {
    event.preventDefault();
    handleAddResource(resource);
    setShowModal(false);
  }

  return (
    <>
      <div className="min-w-320 min-h-80 p-8 m-5 rounded-2xl border flex flex-wrap items-center hover:shadow-lg hover:border-gray-500" onClick={() => setShowModal(true)}>
        <div className="mx-2">Add a Resource</div>
        <div className="flex border rounded-full w-12 h-12 ml-auto text-center items-center justify-center hover:shadow-lg hover:border-gray-500">
          <svg
            className="block"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.00004 4.00605e-05L8.00014 15.9999"
              stroke="#86868B"
            ></path>
            <path d="M0.000131098 7.99987L16 8.00009" stroke="#86868B"></path>
          </svg>
        </div>
      </div>
      {
        showModal ? (<Modal showModal={showModal} setShowModal={setShowModal} title="Add Resource" body={<ResourceForm onSubmit={onSubmit} handleChange={handleChange}/>} submitText="save"/>) : null
      }
    </>
  );
};
