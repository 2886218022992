import { useState, useContext } from "react";
import { DashboardContext } from "./Dashboard";
import { PhoneInput } from "./PhoneInput";
import { toast } from 'react-toastify';

interface Service {
  sname: string;
  minutes: string;
};

interface Resource {
  rname: string;
  color: string;
};

function useDashboardContext() {
  let context = useContext(DashboardContext);
  // If context is undefined, we know we used RadioGroupItem
  // outside of our provider so we can throw a more helpful
  // error!
  if (context === undefined) {
    throw Error(
      "DashboardContext must be used inside of Dashboard component, " +
        "otherwise it will not function correctly."
    );
  }

  // Because of TypeScript's type narrowing, if we make it past
  // the error the compiler knows that context is always defined
  // at this point, so we don't need to do any conditional
  // checking on its values when we use this hook!
  return context;
}


export const PagerForm = ( props: any) => {
  const {addPager, setShowModal} = props;
  const { business } = useDashboardContext();
  const [selectedResource, setSelectedResource] = useState<Resource>({rname:'', color:''});
  const [selectedService, setSelectedService] = useState<Service>({sname:'', minutes:''});
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [notes, setNotes] = useState('');
  const [nameError, setNameError] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const resources = business.resourceData;

  const services = business.serviceData;

  const handleResourceSelection = (resource: Resource) => {
    if (selectedResource?.rname === resource.rname) {
      setSelectedResource({rname:'', color:''});
    } else {
      setSelectedResource(resource);
    }
  };

  const handleServiceSelection = (service: Service) => {
    if (selectedService?.sname === service.sname) {
      setSelectedService({sname:'', minutes:''});
    } else {
      setSelectedService(service);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setNameError('');
    setPhoneError('');

    let formIsValid = true;

    if (!name) {
      setNameError("Please enter customer name");
      formIsValid = false;
    }
    if (!phone) {
      setPhoneError('please enter phone number');
      formIsValid = false;
    }
    if(formIsValid){
      addPager(name, phone, notes, selectedResource?.rname, selectedService?.sname);
      setShowModal(false);
    }
  };

  return (
    <div className="mb-auto overflow-y-auto" style={{height: "50%"}}>
      <div>
        <div className="decoration-neutral-50">
          <form className="grid grid-cols-1 gap-4 p-5 m-auto max-w-lg md:grid-cols-2 lg:max-w-2xl" onSubmit={handleSubmit}>
            <div className="flex flex-col max-w-xs">
              <label className="mb-2 font-light after:content-['*'] after:ml-0.5 after:text-red-500" htmlFor="name">Name</label>
              <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder="Enter name" id="name" value={name} onChange={(e) => setName(e.target.value)}/>
              {nameError && <div className="text-red-500" style={{ color: 'red' }}>{nameError}</div>}
            </div>
            <div className="flex flex-col max-w-xs">
              <label className="mb-2 font-light after:content-['*'] after:ml-0.5 after:text-red-500" htmlFor="phone">Phone</label>
              {/* <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="tel" placeholder="Enter Phone no." id="phone"/> */}
              <PhoneInput onPhoneChange={setPhone} defaultCountry={business.formData.country}/>
              {phoneError && <div className="text-red-500" style={{ color: 'red' }}>{phoneError}</div>}
            </div>
            <div className="flex flex-col max-w-xs">
              <label className="mb-2 font-light" htmlFor="name">Resource</label>
              {
                resources.length?
                <div className="flex flex-col h-32 overflow-y-scroll">
                  {resources.map((resource: Resource, index:any )=> (
                    <button
                      key={index}
                      className={`py-2 px-4 border border-gray-400 rounded-lg hover:bg-gray-200 focus:outline-none ${
                        selectedResource?.rname === resource.rname ? 'bg-gray-300' : ''
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        handleResourceSelection(resource);
                      }}
                    >
                      {resource.rname}
                    </button>
                  ))}
                </div>
                :
                <div className="flex flex-col h-32 overflow-y-scroll text-center font-light justify-center">
                  <h1>No Resources</h1>
                </div>
              }
            </div>
            <div className="flex flex-col max-w-xs">
              <label className="mb-2 font-light" htmlFor="name">Service</label>
              {
                services.length?
                <div className="flex flex-col h-32 overflow-y-scroll">
                  {services.map((service: Service, index:any) => (
                    <button
                      key={index}
                      className={`py-2 px-4 border border-gray-400 rounded-lg hover:bg-gray-200 focus:outline-none ${
                        selectedService?.sname === service.sname ? 'bg-gray-300' : ''
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        handleServiceSelection(service)
                      }}
                    >
                      {service.sname}
                    </button>
                  ))}
                </div>
                :
                <div className="flex flex-col h-32 overflow-y-scroll text-center font-light justify-center">
                  <h1>No Services</h1>
                </div>
              }
            </div>
            <div className="flex flex-col col-span-full max-w-2xs">
              <label className="mb-2 font-light" htmlFor="notes">Notes</label>
              <textarea className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" placeholder="Enter any notes here..." id="message" rows={2} value={notes} onChange={(e) => setNotes(e.target.value)}/>
            </div>
            <button className="text-xl col-span-full inline-flex mx-auto px-6 bg-gray-500 my-auto py-2 rounded-lg text-center text-neutral-50 hover:bg-gray-600">
              Create
              <span className="inline-flex ml-2 my-auto"><svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></span>
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}