import React, { useEffect, useState, useRef } from 'react';

interface LogoUploaderProps {
  onUpload: (logo: File | null) => void;
  url: string | null;
}

export const LogoUploader: React.FC<LogoUploaderProps> = ({ onUpload, url }) => {
  const hiddenElementRef = useRef<HTMLInputElement>(null);
  const [logo, setLogo] = useState<File | null>(null);
  const [logoUrl, setLogoUrl] = useState<string | null>(url);
  const [error, setError] = useState<string | null>(null);

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
      if (!logo) {
          //setLogoUrl(null);
          return
      }

      const objectUrl = URL.createObjectURL(logo)
      setLogoUrl(objectUrl)

      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(objectUrl)
  }, [logo])

  const handleLogoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.type.includes('image/') && file.size <= 400 * 1024) {
      setLogo(file);
      onUpload(file);
      setError(null);
    } else {
      setLogo(null);
      onUpload(null);
      setError("Please upload an image file (jpg, png, svg, jpeg) less than 400kb");
    }
  };

  const handleClick = () => {
    if (hiddenElementRef.current) {
      hiddenElementRef.current.click();
    }
  };

  return (
    <div className="flex flex-col items-center space-y-2">
      {logoUrl ? (
        <div className="mt-4 group">
          <input type="file" id="uploadNewFile" className="opacity-0 hidden" accept="image/*" onChange={handleLogoChange} ref={hiddenElementRef}/>
          <img className="mx-auto mb-3 group-hover:opacity-50 cursor-pointer" src={logoUrl} alt="Logo" width="400" onClick={handleClick}/>
          <div className="transition-all transform translate-y-8 opacity-0 group-hover:opacity-100 group-hover:translate-y-0 text-center">
            Upload New Logo
          </div>
        </div>
      ) : (
        <div className="flex justify-center">
            <div className="max-w-2xl rounded-lg">
                <div className="m-5">
                    <label className="inline-block mb-2 text-gray-500">Upload Image(jpg,png,svg,jpeg), Max 4KB</label>
                    <div className="flex items-center justify-center w-full">
                        <label
                            className="flex flex-col w-full h-32 border-4 border-blue-200 border-dashed hover:bg-gray-100 hover:border-gray-300">
                            <div className="flex flex-col items-center justify-center pt-7">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 text-gray-400 group-hover:text-gray-600"
                                    fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                                </svg>
                                <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                                    Upload an Image</p>
                            </div>
                            <input type="file" className="opacity-0" accept="image/*" onChange={handleLogoChange}/>
                        </label>
                    </div>
                    {error && <div className="text-red-500" style={{ color: 'red' }}>{error}</div>}
                </div>
            </div>
        </div> 
      )}
    </div>
  );
};