import { useEffect } from 'react';
import { Navigate, useParams } from "react-router-dom";
import { Buzzer } from "../Buzzer";
import { Waitlist } from '../Waitlist';
export const Process = (props: any) => {
  useEffect(() => {
    props.funcNav(false);
  }, [props]);
  const {slug, id} = useParams();
  if(slug && id) {
    return <Buzzer slug={slug} id={id}/>
  } else if (slug) {
    return <Waitlist slug={slug}/>
  }
  return <h1>404 Not Found</h1>
}