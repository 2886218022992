export const Footer = () => {
  return (
    <footer className="text-center">
      <div className="">
        <p>© {new Date().getFullYear()} Buzzerr. All rights reserved.</p>
      </div>
      <div className="">
        <p>
          
        </p>
      </div>
    </footer>
  )
}