import React, { useState, useEffect } from 'react';

export const WaitlistItem = (props: any) => {
  const {item, itemNo, showWaitTime, backgroundColor, textColor} = props;
  const [waitTime, setWaitTime] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentTime = new Date().getTime();
      setWaitTime(Math.round((currentTime - item.dateCreated) / 60000));
    }, 1000);
    return () => clearInterval(intervalId);
  }, [item.dateCreated]);

  const days = Math.floor(waitTime / 1440);
  const hours = Math.floor((waitTime - days * 1440) / 60);
  const minutes = waitTime % 60;
  
  return (
    <li className="flex align-items-center p-0" style={{ backgroundColor: backgroundColor, color: textColor}}>
      <div className="p-1 p-md-4 text-center">
        {itemNo}.
      </div>
      <div  className="py-1 py-md-2"></div>
      <div
        className="flex-grow p-1 p-md-3 fs-3"
      >
        {item.name}
      </div>
      {showWaitTime && (
        <div
          id="wait-time"
          className="flex my-auto"
          >
          <div className="mr-2">⏳</div>
          <div>{days > 0 ? `${days}d ` : ''}{hours > 0 ? `${hours}h ` : ''}{minutes}m</div>
        </div>
      )}
      <div className="my-2"></div>
    </li>
  )
}