export interface SelectMenuOption {
  value: string;
  title: string;
}

export const Roles: SelectMenuOption[] = [
  {
    title: 'Manager',
    value: 'MANAGER'
  },
  {
    title: 'Employee',
    value: 'EMPLOYEE'
  },
  {
    title: 'Intern',
    value: 'INTERN'
  },
  {
    title: 'Consultant',
    value: 'CONSULTANT'
  },
  {
    title: 'Contractor',
    value: 'CONTRACTOR'
  },
  {
    title: 'VP/Director',
    value: 'VP_DIRECTOR'
  },
  {
    title: 'Owner',
    value: 'OWNER'
  },
  {
    title: 'Other',
    value: 'OTHER'
  },
]