export const ResourceForm = ( props: any) => {
  const {onSubmit, handleChange} = props;

  return (
    <div className="mb-auto">
      <div>
        <div className="decoration-neutral-50">
          <p className="text-center">
          A “resource” can be anything or anyone that customers can choose from (ie. staff members, table numbers, equipment etc.).
          </p>
          <form className="grid grid-cols-1 gap-4 p-5 m-auto max-w-lg md:grid-cols-2 lg:max-w-2xl" onSubmit={onSubmit}>
            <div className="flex flex-col max-w-xs">
              <label className="mb-2 font-light" htmlFor="Resource">Resource name or type</label>
              <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder="e.g. table 1" id="resource" name="rname" onChange={handleChange}/>
            </div>
            <div className="flex flex-col max-w-xs">
              <label
                className="mb-2 font-light"
                htmlFor="color"
              >
                Choose a color
              </label>
              <input
                type="color"
                name="color"
                id="color"
                defaultValue="#0000ff"
                className="bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 rounded-md py-2 px-4 block w-full leading-5 text-gray-700 transition duration-150 ease-in-out"
                onChange={handleChange}
              />
            </div>
            <button className="text-xl col-span-full inline-flex mx-auto px-6 bg-blue-500 my-auto py-2 rounded-lg text-center text-neutral-50 hover:bg-blue-700">
              Save
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}