import { Link, useNavigate } from "react-router-dom";
import priceTag from "../../assets/tag.png";
import { auth } from "../../config/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { Subscribe } from "./Subscribe";

export const Pricing = () => {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  return (
    <div className="mb-auto">
      <div>
        <div className="decoration-neutral-50">
          <div className="m-auto max-w-lg">
              <div className="flex justify-center">
                <span className="my-auto mr-3">
                  <img src={priceTag} alt="Pricing" width="52" height="36"/>
                </span>
                <h2 className="my-auto text-4xl font-semibold leading-snug text-center">Plans & Pricing</h2>
              </div>
              <p className="p-3 text-lg font-light leading-8 text-center">Simple pricing, best for everyone</p>
          </div>
          <div className="container m-auto max-w-6xl p-4 lg:p-8">
            <div className="grid grid-cols-1 gap-10 m-auto max-w-5xl md:grid-cols-2 lg:gap-44 text-neutral">
              <div className="flex flex-col bg-white rounded-lg shadow-lg">
                <div className="mt-5 text-center">
                  <span className="inline-block mb-8 text-4xl font-bold">Free Plan</span>
                </div>
                <div className="m-auto px-4">
                  <ul className="leading-loose marker:text-sky-400 list-disc">
                    <li className="mb-3">5 Active Buzzers</li>
                    <li className="mb-3">Short Links</li>
                    <li className="mb-3">QR/WhatsApp/SMS/Email sharing</li>
                    <li className="mb-3">Up to 500 Buzzer records per month</li>
                    <li className="mb-3">7 day Buzzer record histroy</li>
                  </ul>
                </div>
                {
                  !user && <div className="p-5 text-center">
                    <Link className="m-auto font-semibold text-white bg-gray-500  hover:bg-gray-600 py-2 px-4 rounded-lg" to="/login">Sign Up</Link>
                  </div>
                }
                {
                  user && <div className="p-5 text-center">
                    <button className="m-auto font-semibold text-white bg-gray-500  hover:bg-gray-600 py-2 px-4 rounded-lg" onClick={() => navigate("/dashboard")}>Get Started</button>
                  </div>
                }
              </div>
              <div className="flex flex-col bg-white rounded-lg shadow-lg">
                <div className="mt-5 text-center">
                  <span className="inline-block mb-8 text-4xl font-bold">$19/Month</span>
                </div>
                <div className="m-auto px-4">
                  <ul className="leading-loose marker:text-sky-400 list-disc">
                    <li className="mb-3">No limit on Active Buzzers</li>
                    <li className="mb-3">Short Links</li>
                    <li className="mb-3">QR/WhatsApp/SMS/Email sharing</li>
                    <li className="mb-3">Up to 10000 Buzzer records per month</li>
                    <li className="mb-3">30 day Buzzer record histroy</li>
                  </ul>
                </div>
                {
                  !user && <div className="p-5 text-center">
                    <Link className="m-auto font-semibold text-white bg-gray-500  hover:bg-gray-600 py-2 px-4 rounded-lg" to="/login">Sign Up</Link>
                  </div>
                }
                {
                  user && <div className="p-5 text-center">
                    {/* <Subscribe user={user}/> */}
                    <Link to="/dashboard" state={{ defaultTab: "Subscription" }}>
                      <button className="m-auto font-semibold text-white bg-gray-500  hover:bg-gray-600 py-2 px-4 rounded-lg">Subscribe</button>
                    </Link>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}