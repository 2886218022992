import React, { useState } from 'react';
import business from "../../assets/business.png";
import services from "../../assets/services.png";
import resources from "../../assets/resource.png"
import hoursImg from "../../assets/hours.png"
import StepProgressBar from './StepProgressBar';
import { CreateService } from './CreateService';
import { CreateResource } from "./CreateResource";
import OperatingHoursForm from './OperatingHoursForm';
import { addDoc, collection, getDocs, query, serverTimestamp, where } from "firebase/firestore";
import { db } from "../../config/firebase";
import { Roles } from "./roles";
import { Countries } from "./countries";

const Onboarding = (props: any) => {

  const { user, setOnboarding } = props;

  type Service = {
    sname: string;
    minutes: string;
  };

  type Resource = {
    rname: string;
    color: string;
  };

  type Hours = {
    open: string;
    close: string;
    days: string[];
  }
  
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({bname: '', category: '', slug: '', country: 'US', role: '', website: ''});
  const [serviceData, setServiceData] = useState<Service[]>([]);
  const [resourceData, setResourceData] = useState<Resource[]>([]);
  const [hours, setHours] = useState<Hours>({
    open: "",
    close: "",
    days: [],
  });
  const [bnameError, setBnameError] = useState('');
  const [categoryError, setCategoryError] = useState('');
  const [slugError, setSlugError] = useState('');
  const [roleError, setRoleError] = useState('');
  const [websiteError, setWebsiteError] = useState('');

  const totalSteps = 4;

  const businessRef = collection(db, "business");

  const handleChange = (e : any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    let formIsValid = true;
    if(step === 1) {
      // Reset the error messages
      setBnameError('');
      setCategoryError('');
      setSlugError('');
      setRoleError('');
      setWebsiteError('');
      // Validate the form inputs
      if (!formData.bname) {
        setBnameError("Please enter your organization's name");
        formIsValid = false;
      }
      if (!formData.category) {
        setCategoryError('please select a business category');
        formIsValid = false;
      } 
      if (!formData.slug) {
        setSlugError('Please enter a slug for your business');
        formIsValid = false;
      } else if(!/^\w+$/.test(formData.slug)) {
        setSlugError('Slug can only contain letters and numbers');
        formIsValid = false;
      } else if (formData.slug) {
        if(formData.slug === "dashboard" || formData.slug === "pricing" || formData.slug === "contact" || formData.slug === "login"){
          setSlugError('Slug is not available, select another slug');
          formIsValid = false;
        } else {
          const slugExists = await isExistingSlug(formData.slug);
          if (slugExists) {
            setSlugError('Slug is not available, select another slug');
            formIsValid = false;
          }
        }
      }
    }
    if (formIsValid) {
      setStep(step + 1);
    }
  }

  async function isExistingSlug(slug: string) {
    const qry = query(businessRef, where("formData.slug", "in", [slug]));
    const snapshot = await getDocs(qry);
    if (snapshot.size > 0) {
      return true;
    }
    return false;
  }

  const handleAddService = (service: any) => {
    setServiceData([...serviceData, service])
  }

  const handleRemoveService = (id: any) => {
    setServiceData(serviceData.filter((item, index) => index !== id));
  }

  const handleAddResource = (resource: any) => {
    setResourceData([...resourceData, resource])
  }

  const handleRemoveResource = (id: any) => {
    setResourceData(resourceData.filter((item, index) => index !== id));
  }

  const handleLastStep = async () => {
    const doc = await addDoc(businessRef, {
      createdAt: serverTimestamp(),
      user: user.email,
      userId: user.uid,
      formData,
      serviceData,
      resourceData,
      hours
    });
    setOnboarding(false);
  }

  switch (step) {
    case 1:
      return (
        <div className="mb-auto"> 
        <div>
          <div className="decoration-neutral-50">
            <StepProgressBar currentStep={step} totalSteps={totalSteps} heading="Your business"/>
            <div className="m-auto max-w-lg">
              <div className="flex flex-col justify-center justify-items-center sm:flex-row">
                <span className="m-auto sm:my-auto sm:mr-3">
                  <img src={business} alt="Contact us mail box" width="52" height="36"/>
                </span>
                <h2 className="my-auto text-4xl font-semibold leading-snug text-center">Tell us about your business</h2>
              </div>
              <p className="p-3 text-lg font-light leading-8"></p>
            </div>
            <form className="grid grid-cols-1 gap-4 p-5 m-auto max-w-lg md:grid-cols-2 lg:max-w-4xl" onSubmit={handleSubmit}>
              <div className="flex flex-col max-w">
                <label className="mb-2 font-light" htmlFor="bname">Business name *</label>
                <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder="Ex: SunsetBlu" id="bname" name="bname" value={formData.bname} onChange={handleChange}/>
                {bnameError && <div className="text-red-500" style={{ color: 'red' }}>{bnameError}</div>}
              </div>
              <div className="flex flex-col max-w">
                <label className="mb-2 font-light" htmlFor="category">Business category *</label>
                <select className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 bg-[url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAh0lEQVQ4T93TMQrCUAzG8V9x8QziiYSuXdzFC7h4AcELOPQAdXYovZCHEATlgQV5GFTe1ozJlz/kS1IpjKqw3wQBVyy++JI0y1GTe7DCBbMAckeNIQKk/BanALBB+16LtnDELoMcsM/BESDlz2heDR3WePwKSLo5eoxz3z6NNcFD+vu3ij14Aqz/DxGbKB7CAAAAAElFTkSuQmCC')] bg-no-repeat bg-right bg-origin-content" id="category" name="category" value={formData.category} onChange={handleChange}>
                  <option value="">Select a category</option>
                  <option value="ANIMAL" >Animal &amp; Pet</option>
                  <option value="APP" >App</option>
                  <option value="AUTOMOTIVE" >Automotive</option>
                  <option value="FINANCE" >Banking &amp; Finance</option>
                  <option value="BAR" >Bar &amp; Nightclub</option>
                  <option value="SALON" >Beauty salon</option>
                  <option value="CAFE" >Cafe</option>
                  <option value="DENTAL" >Dental office</option>
                  <option value="DOCTOR" >Doctor &amp; Hospital</option>
                  <option value="ENTERTAINMENT" >Entertainment</option>
                  <option value="EVENT" >Events</option>
                  <option value="GOVERNMENT" >Government</option>
                  <option value="HAIRDRESSER" >Hair &amp; Barber</option>
                  <option value="LODGING" >Housing &amp; Lodging</option>
                  <option value="INDIVIDUAL" >Individual</option>
                  <option value="IT" >IT Service</option>
                  <option value="LEGAL" >Legal Services</option>
                  <option value="LOGISTICS" >Logistics</option>
                  <option value="OTHER" >Other</option>
                  <option value="REAL-ESTATE" >Real estate</option>
                  <option value="RENTAL" >Rentals</option>
                  <option value="RESTAURANT" >Restaurant</option>
                  <option value="SCHOOL" >School &amp; University</option>
                  <option value="SPA" >Spa &amp; Massage</option>
                  <option value="RETAIL" >Store &amp; Retail</option>
                  <option value="HEALTH" >Training &amp; Gym</option>
                  <option value="TRAVEL" >Travel &amp; Tourism</option>
                </select>
                {categoryError && <div className="text-red-500" style={{ color: 'red' }}>{categoryError}</div>}
              </div>
              <div className="flex flex-col max-w">
                <label className="mb-2 font-light" htmlFor="plink">Choose a public link *</label>
                <div className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                  <span>
                    {window.location.origin}/
                  </span>
                  <input className="bg-gray-200 text-gray-700 focus:outline-none" type="text" placeholder="Enter a slug" id="slug" name="slug" value={formData.slug} onChange={handleChange}/>
                </div>
                {slugError && <div className="text-red-500" style={{ color: 'red' }}>{slugError}</div>}
              </div>
              <div className="flex flex-col max-w">
                <label className="mb-2 font-light" htmlFor="country">Country *</label>
                <select className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 bg-[url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAh0lEQVQ4T93TMQrCUAzG8V9x8QziiYSuXdzFC7h4AcELOPQAdXYovZCHEATlgQV5GFTe1ozJlz/kS1IpjKqw3wQBVyy++JI0y1GTe7DCBbMAckeNIQKk/BanALBB+16LtnDELoMcsM/BESDlz2heDR3WePwKSLo5eoxz3z6NNcFD+vu3ij14Aqz/DxGbKB7CAAAAAElFTkSuQmCC')] bg-no-repeat bg-right bg-origin-content" id="country" name="country" value={formData.country} onChange={handleChange}>
                  {
                    Countries.map((country, index) => {
                      return <option key={index} value={country.value}>{country.title}</option>
                    })
                  }
                </select>
              </div>
              <div className="flex flex-col max-w">
                <label className="mb-2 font-light" htmlFor="role">Role at the company (optional)</label>
                <select className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 bg-[url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAh0lEQVQ4T93TMQrCUAzG8V9x8QziiYSuXdzFC7h4AcELOPQAdXYovZCHEATlgQV5GFTe1ozJlz/kS1IpjKqw3wQBVyy++JI0y1GTe7DCBbMAckeNIQKk/BanALBB+16LtnDELoMcsM/BESDlz2heDR3WePwKSLo5eoxz3z6NNcFD+vu3ij14Aqz/DxGbKB7CAAAAAElFTkSuQmCC')] bg-no-repeat bg-right bg-origin-content" id="role" name="role" value={formData.role} onChange={handleChange}>
                  <option value="">Select your current role</option>
                  {
                    Roles.map((role, index) => {
                      return <option key={index} value={role.value}>{role.title}</option>
                    })
                  }
                </select>
                {roleError && <div className="text-red-500" style={{ color: 'red' }}>{roleError}</div>}
              </div>
              <div className="flex flex-col max-w">
                <label className="mb-2 font-light" htmlFor="website">Company website (optional)</label>
                <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder="www.sunsetblu.com" id="website" name="website" value={formData.website} onChange={handleChange}/>
                {websiteError && <div className="text-red-500" style={{ color: 'red' }}>{websiteError}</div>}
              </div>
              <button className="text-xl col-span-full inline-flex mx-auto mt-6 px-6 bg-blue-500 my-auto py-2 rounded-lg text-center text-neutral-50 hover:bg-blue-700">
                Next
                <span className="inline-flex ml-2 my-auto"><svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></span>
              </button>
            </form>
          </div>
        </div>
      </div>
      );
    case 2:
      return (
        <div className="mb-auto">
        <div>
          <div className="decoration-neutral-50">
            <StepProgressBar currentStep={step} totalSteps={totalSteps} heading="Set up services"/>
            <button className="bg-transparent hover:bg-gray-300 text-gray-800 font-semibold hover:text-gray-600 py-2 px-4 border border-gray-400 hover:border-transparent rounded-full" 
              onClick={() => setStep(step-1)}>
              <svg
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 19l-7-7 7-7"
                />
              </svg>
            </button>
            <div className="m-auto max-w-xl flex flex-col justify-around">
              <div>
                <div className="flex flex-col justify-center justify-items-center sm:flex-row">
                  <span className="m-auto sm:my-auto sm:mx-0 sm:mr-3">
                    <img src={services} alt="services" width="52" height="36"/>
                  </span>
                  <h2 className="my-auto text-4xl font-semibold leading-snug text-center">Offered services</h2>
                </div>
                <p className="p-3 text-lg font-light leading-8 text-center">Select from this list or add your own.</p>
              </div>
              <div className="flex justify-center flex-wrap">
                {
                  serviceData.map((service, index) => {
                    return (
                      <div key={index} className="w-60 h-30 p-8 m-5 rounded-2xl border flex flex-wrap items-center">
                        <div>
                          <div className="mx-2">{service.sname}</div>
                          <div className="mx-2">{service.minutes? service.minutes+'m': null}</div>
                        </div>
                        <div className="flex border rounded-full w-12 h-12 ml-auto text-center items-center justify-center hover:shadow-lg hover:border-gray-500" onClick={() => {handleRemoveService(index)}}>
                          <svg
                            className="block rotate-45"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.00004 4.00605e-05L8.00014 15.9999"
                              stroke="#86868B"
                            ></path>
                            <path d="M0.000131098 7.99987L16 8.00009" stroke="#86868B"></path>
                          </svg>
                        </div>
                      </div>
                    );
                  })
                }
                <CreateService handleAddService={handleAddService}/>
              </div>
              <div className="flex flex-col flex-wrap items-center justify-center sm:flex-row">
                <button className="text-xl col-span-full inline-flex mx-auto mt-6 mb-6 px-6 bg-blue-500 my-auto py-2 rounded-lg text-center text-neutral-50 hover:bg-blue-700" onClick={handleSubmit}>
                  Next
                  <span className="inline-flex ml-2 my-auto"><svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      );
    case 3:
      return (
        <div className="mb-auto">
        <div>
          <div className="decoration-neutral-50">
            <StepProgressBar currentStep={step} totalSteps={totalSteps} heading="Set up resources"/>
            <button className="bg-transparent hover:bg-gray-300 text-gray-800 font-semibold hover:text-gray-600 py-2 px-4 border border-gray-400 hover:border-transparent rounded-full" 
              onClick={() => setStep(step-1)}>
              <svg
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 19l-7-7 7-7"
                />
              </svg>
            </button>
            <div className="m-auto max-w-xl flex flex-col justify-around">
              <div>
                <div className="flex flex-col justify-center justify-items-center sm:flex-row">
                  <span className="m-auto sm:my-auto sm:mx-0 sm:mr-3">
                    <img src={resources} alt="resources" width="52" height="36"/>
                  </span>
                  <h2 className="my-auto text-4xl font-semibold leading-snug text-center">Available resources</h2>
                </div>
                <p className="p-3 text-lg font-light leading-8 text-center">Select from this list or add your own.</p>
              </div>
              <div className="flex justify-center flex-wrap">
                {
                  resourceData.map((resource, index) => {
                    return (
                      <div key={index} className="w-60 h-30 p-8 m-5 rounded-2xl border flex flex-wrap items-center">
                        <div className="flex">
                          <div className="mx-2 w-6 h-6 rounded-full" style={{ backgroundColor: `${resource.color? resource.color: "#0000ff"}`}}></div>
                          <div className="mx-2">{resource.rname}</div>
                        </div>
                        <div className="flex border rounded-full w-12 h-12 ml-auto text-center items-center justify-center hover:shadow-lg hover:border-gray-500" onClick={() => {handleRemoveResource(index)}}>
                          <svg
                            className="block rotate-45"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.00004 4.00605e-05L8.00014 15.9999"
                              stroke="#86868B"
                            ></path>
                            <path d="M0.000131098 7.99987L16 8.00009" stroke="#86868B"></path>
                          </svg>
                        </div>
                      </div>
                    );
                  })
                }
                <CreateResource handleAddResource={handleAddResource}/>
              </div>
              <div className="flex flex-col flex-wrap items-center justify-center sm:flex-row">
                <button className="text-xl col-span-full inline-flex mx-auto mt-6 mb-6 px-6 bg-blue-500 my-auto py-2 rounded-lg text-center text-neutral-50 hover:bg-blue-700" onClick={handleSubmit}>
                  Next
                  <span className="inline-flex ml-2 my-auto"><svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      );
    case 4:
      return (
        <div className="mb-auto">
          <div>
            <div className="decoration-neutral-50">
              <StepProgressBar currentStep={step} totalSteps={totalSteps} heading="Set your opening hours"/>
              <button className="bg-transparent hover:bg-gray-300 text-gray-800 font-semibold hover:text-gray-600 py-2 px-4 border border-gray-400 hover:border-transparent rounded-full" 
                onClick={() => setStep(step-1)}>
                <svg
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </button>
              <div className="m-auto max-w-xl flex flex-col justify-around">
                <div>
                  <div className="flex flex-col justify-center justify-items-center sm:flex-row">
                    <span className="m-auto sm:my-auto sm:mx-0 sm:mr-3">
                      <img src={hoursImg} alt="hours" width="52" height="36"/>
                    </span>
                    <h2 className="my-auto text-4xl font-semibold leading-snug text-center">Add your hours of operation.</h2>
                  </div>
                </div>
                <OperatingHoursForm handleLastStep={handleLastStep} hours={hours} setHours={setHours}/>
              </div>
            </div>
          </div>
        </div>
      );
    default:
      return null;
  }
}

export default Onboarding;