import React from 'react';

interface ProgressBarProps {
  currentStep: number;
  totalSteps: number;
  heading: string;
}

const StepProgressBar = ({ currentStep, totalSteps, heading }: ProgressBarProps) => {
  return (
    <>
      <div>{heading}</div>
      <div className="bg-gray-300 rounded-md mb-10">
        <div
          className={`bg-blue-700 h-2 rounded-md w-${(currentStep / totalSteps) * 100}`} style={{width:`${(currentStep / totalSteps)* 100}%`}}
        ></div>
      </div>
    </>
  );
};

export default StepProgressBar;
