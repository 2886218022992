import {useEffect} from 'react';
import * as firebaseui from 'firebaseui';
import { auth } from "../config/firebase";
import { EmailAuthProvider } from "firebase/auth"
import 'firebaseui/dist/firebaseui.css';
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";


export const Login = () => {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(()=> {
    if (user) {
      navigate("/dashboard");
    }

    let ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);

    ui.start('#firebaseui-auth-container', {
      signInOptions: [
        {
          provider : EmailAuthProvider.PROVIDER_ID,
          signInMethod: EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
          requireDisplayName: false,
        }
      ],
      signInSuccessUrl: '/dashboard',
      tosUrl: '/terms',
      privacyPolicyUrl: '/privacy'
      // Other config options...
    });
  },[user, navigate])
  return (
  <>
    <div id='firebaseui-auth-container' className='mb-auto'></div>
    <style>{`
      .firebaseui-button {
        background-color: #8e8d8f !important;
        color: white !important;
      }

      .firebaseui-textfield.mdl-textfield .firebaseui-label:after {
          background-color: #8e8d8f !important;
      }

      .mdl-progress > .progressbar {
          background-color: #8e8d8f !important;
      }

      .mdl-progress > .bufferbar {
          background-image: linear-gradient(
                  90deg,
                  hsla(0, 0%, 100%, 0.7),
                  hsla(0, 0%, 100%, 0.7)
              ),
              linear-gradient(90deg, #8e8d8f, #8e8d8f) !important;
          z-index: 0;
          left: 0;
      }

      .mdl-progress:not(.mdl-progress--indeterminate) > .auxbar,
      .mdl-progress:not(.mdl-progress__indeterminate) > .auxbar {
          background-image: linear-gradient(
                  90deg,
                  hsla(0, 0%, 100%, 0.9),
                  hsla(0, 0%, 100%, 0.9)
              ),
              linear-gradient(90deg, #8e8d8f, #8e8d8f) !important;
      }`}
    </style>
  </>
  )
}

