import { useState } from "react";
import { Modal } from "../../components/Modal";
import { ServiceForm } from "./ServiceForm";

export const CreateService = (props: any) => {

  const {handleAddService} = props;

  const [showModal, setShowModal] = useState(false);
  const [service, setService] = useState({sname: '', minutes: ''})

  const handleChange = (e : any) => {
    setService({ ...service, [e.target.name]: e.target.value });
  }

  const onSubmit = (event : React.FormEvent) => {
    event.preventDefault();
    handleAddService(service);
    setShowModal(false);
  }

  return (
    <>
      <div className="w-60 h-30 p-8 m-5 rounded-2xl border flex flex-wrap items-center hover:shadow-lg hover:border-gray-500" onClick={() => setShowModal(true)}>
        <div className="mx-2">Add a service</div>
        <div className="flex border rounded-full w-12 h-12 ml-auto text-center items-center justify-center hover:shadow-lg hover:border-gray-500">
          <svg
            className="block"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.00004 4.00605e-05L8.00014 15.9999"
              stroke="#86868B"
            ></path>
            <path d="M0.000131098 7.99987L16 8.00009" stroke="#86868B"></path>
          </svg>
        </div>
      </div>
      {
        showModal ? (<Modal showModal={showModal} setShowModal={setShowModal} title="Add Service" body={<ServiceForm onSubmit={onSubmit} handleChange={handleChange}/>} submitText="save"/>) : null
      }
    </>
  );
};
